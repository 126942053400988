Ember.computed.countTruthy = function() {
	var keys = Array.prototype.slice.call(arguments, 0);
	var computed = Ember.computed(function() {
		var context = this;

		return Ember.A(keys).filter(function(key) {
			return context.get(key);
		}).get('length');
	});

	computed.property.apply(computed, keys);
	return computed;
};

/**
 * Extends the SortableMixin by allowing sorting by multiple orders.
 *
 * sortProperties - array of strings
 * sortAscending - default sort order
 * sortAscendingProperties - properties which are listed here will be sorted in ascending order, those which are not listed will be sorted according to sortAscending
 */
Ember.MultiSortableMixin = Ember.Mixin.create(Ember.SortableMixin, {
	/**
	Specifies the arrangedContent's sort direction

	@property {Array} sortAscendingProperties
	*/
	sortAscendingProperties: null,
	orderBy: function(item1, item2) {
		var result = 0,
			sortProperties = Ember.get(this, 'sortProperties'),
			sortAscending = Ember.get(this, 'sortAscending'),
			sortAscendingProperties = Ember.get(this, 'sortAscendingProperties');

		Ember.assert("You need to define `sortProperties`", !!sortProperties);

		Ember.EnumerableUtils.forEach(sortProperties, function(propertyName) {
			if (result === 0) {
				result = Ember.compare(Ember.get(item1, propertyName), Ember.get(item2, propertyName));
				var sa = (sortAscendingProperties && sortAscendingProperties.indexOf(propertyName) > -1) || sortAscending;
				if ((result !== 0) && !sa) {
					result = (-1) * result;
				}
			}
		});

		return result;
	},
	arrangedContent: Ember.computed('content', 'sortProperties.@each', 'sortAscendingProperties.@each', 'sortAscending', function(key, value) {
		var content = Ember.get(this, 'content'),
			isSorted = Ember.get(this, 'isSorted'),
			sortProperties = Ember.get(this, 'sortProperties'),
			self = this;

		if (content && isSorted) {
			content = content.slice();
			content.sort(function(item1, item2) {
				return self.orderBy(item1, item2);
			});
			Ember.EnumerableUtils.forEach(content, function(item) {
				Ember.EnumerableUtils.forEach(sortProperties, function(sortProperty) {
					Ember.addObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
				}, this);
			}, this);
			return Ember.A(content);
		}

		return content;
	}),
	sortAscendingDidChange: Ember.observer(function() {
		// Do nothing
	}, 'sortAscending')
});

var switchedCardComputed = function(switchKey, cardPath) {
	return Ember.computed(function() {
		var cards = this.get('cards');

		if (!cards || !App.switches.isActive(switchKey)) {
			return null;
		}

		return this.get('cards').findBy('uniqueId', cardPath);
	}).property('cards.@each.uniqueId');
};

App.PlanselectorView = Ember.View.extend({
	didInsertElement: function() {
		$('a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('class="note orange"') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});


function calChoiceConstrains(chosenPlans, currentPlan) {
	if (currentPlan.get('isCalChoiceBronze')) {
		for(var i = 0; i < chosenPlans.get('length'); i++) {
			if(chosenPlans.objectAt(i).get('isCalChoiceGold') || chosenPlans.objectAt(i).get('isCalChoicePlatinum')) {
				return true;
			}
		}
	}
	else if (currentPlan.get('isCalChoiceSilver')) {
		for(var i = 0; i < chosenPlans.get('length'); i++) {
			if(chosenPlans.objectAt(i).get('isCalChoicePlatinum')) {
				return true;
			}
		}
	}
	else if (currentPlan.get('isCalChoiceGold')) {
		for(var i = 0; i < chosenPlans.get('length'); i++) {
			if(chosenPlans.objectAt(i).get('isCalChoiceBronze')) {
				return true;
			}
		}
	}
	else if (currentPlan.get('isCalChoicePlatinum')) {
		for(var i = 0; i < chosenPlans.get('length'); i++) {
			if(chosenPlans.objectAt(i).get('isCalChoiceBronze') || chosenPlans.objectAt(i).get('isCalChoiceSilver')) {
				return true;
			}
		}
	}

	return false;
}


function blueCrossConstraintsSatisfied(plans, currentPlan) {
	var statewidePPO = false;
	var selectPPO = false;
	var statewideHMO = false;
	var selectHMO = false;

	plans.forEach(function(plan) {
		if (plan.get('isBlueCrossStatewidePlan')) {
			if (plan.get('isPPO')) {
				statewidePPO = true;
			}
			else {
				statewideHMO = true;
			}
		}
		if (plan.get('isBlueCrossSelectPlan')) {
			if (plan.get('isPPO')) {
				selectPPO = true;
			}
			else {
				selectHMO = true;
			}
		}
	});

	if(currentPlan.get('isPPO')) {
		if(currentPlan.get('isBlueCrossStatewidePlan')) {
			return !selectPPO;
		}
		if(currentPlan.get('isBlueCrossSelectPlan')) {
			return !statewidePPO;
		}
	}
	else if(currentPlan.get('isHMO')) {
		if(currentPlan.get('isBlueCrossStatewidePlan')) {
			return !selectHMO;
		}
		if(currentPlan.get('isBlueCrossSelectPlan')) {
			return !statewideHMO;
		}
	}

	return true;
}


var _PlanSelectorPremiumTierMixin = Ember.Mixin.create({
	isSelectedYouPremium: Ember.computed.equal('selectedPriceLevel', 'avgYouPremium'),
	isSelectedYouAndSpousePremium: Ember.computed.equal('selectedPriceLevel', 'avgYouAndSpousePremium'),
	isSelectedYouAndChildPremium: Ember.computed.equal('selectedPriceLevel', 'avgYouAndChildPremium'),
	isSelectedFamilyPremium: Ember.computed.equal('selectedPriceLevel', 'avgFamilyPremium'),

	isYouPremium: Ember.computed.equal('priceLevel', 'avgYouPremium'),
	isYouAndSpousePremium: Ember.computed.equal('priceLevel', 'avgYouAndSpousePremium'),
	isYouAndChildPremium: Ember.computed.equal('priceLevel', 'avgYouAndChildPremium'),
	isFamilyPremium: Ember.computed.equal('priceLevel', 'avgFamilyPremium')
});

// Base class that only medical selector controllers inherit from
App.SelectorController = _TransactionArraySavingController.extend(_PlanSelectorPremiumTierMixin, {
	lineOfCoverage: 'medical',
	hmoppoFilter: "PPO",
	sortProperty: "avgYouPremium",
	selectedSortProperty: "avgYouPremium",
	planFilter: "",
	excludeFilter: "",
	carrierFilter: "",
	isFilteringCarrier: Ember.computed(function() {
		return !(Ember.isEmpty(this.get('carrierFilter')) || Ember.isEmpty(this.get('companyMedicalCarrier.carrier')));
	}).property('carrierFilter', 'companyMedicalCarrier.carrier'),
	hasCarrier: Ember.computed.bool('companyMedicalCarrier.carrier'),
	isLoading: false,
	showOverview: function(plan, inPackage, addToCart, isShop) {
		plan.set('inPackage', inPackage);
		if (addToCart) {
			plan.set('addToCart', addToCart);
		}
		else {
			plan.set('addToCart', false);
		}
		if (isShop) {
			plan.set('isShop', isShop);
		}
		else {
			plan.set('isShop', false);
		}
		this.send('showModal', 'plan.overview', plan);
	},
	scrollBelow: function() {
		$('html, body').animate({
			scrollTop: $("#alternativePlans").offset().top - 100
		}, 1000);
	},
	filteredAllPlans: function() {
		var hmoppoFilter = this.get('hmoppoFilter');
		var planFilter = this.get('planFilter');
		var excludeFilter = this.get('excludeFilter');
		var carrierFilter = this.get('carrierFilter');
		var filteredContent;
		if (hmoppoFilter == 'PPO') {
			// PPO tab: PPO or POS
			var ppoContent = this.filterProperty('HMOPPO', 'PPO');
			filteredContent = this.filterProperty('HMOPPO', 'POS');
			filteredContent.pushObjects(ppoContent);
		}
		if (hmoppoFilter == 'HMO') {
			// HMO tab: HMO or EPO
			var hmoContent = this.filterProperty('HMOPPO', 'HMO');
			filteredContent = this.filterProperty('HMOPPO', 'EPO');
			filteredContent.pushObjects(hmoContent);
		}
		if (hmoppoFilter == 'HD') {
			// HD tab: HD, HSA or HSA compatibility is True
			filteredContent = this.get('content').filter(function(item) {
				return item.get('HMOPPO') === 'HD' || item.get('HMOPPO') === 'HSA' ||
						item.get('HSA') === true;
			});
		}
		else {
			filteredContent = this.filterProperty('HMOPPO', hmoppoFilter);
		}

		// This is used for add carrier, change plans and open enrollment.
		if (planFilter) {
			filteredContent = filteredContent.filterProperty('carrier', planFilter);
		}

		filteredContent.toArray().forEach(function(item) {
			if (item.get('carrier') == 'SeeChange') {
				filteredContent.removeObject(item);
			}
		});

		// This is used for switch carrier.
		if (excludeFilter) {
			filteredContent.toArray().forEach(function(item) {
				if (item.get('carrier') == excludeFilter) {
					filteredContent.removeObject(item);
				}
			});
		}

		if (carrierFilter) {
			filteredContent = filteredContent.filterProperty('carrier', carrierFilter);
		}

		var selectedPlanIds = this.get('companyMedicalPlans').mapBy('id');
		if (selectedPlanIds) {
			filteredContent = filteredContent.filter( function(item) {
				return !(selectedPlanIds.contains(item.get('id')));
			});
		}

		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: [this.get('sortProperty')],
			content: filteredContent
		});
	}.property('hmoppoFilter', 'planFilter', 'excludeFilter', 'carrierFilter', 'companyMedicalPlans.@each', 'sortProperty'),
	sortedSelectedPlans: function() {
		var plans = this.get('companyMedicalPlans');
		if (plans) {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: [this.get('selectedSortProperty')],
				content: plans
			});
		}

		return plans;
	}.property('companyMedicalPlans', 'selectedSortProperty'),
	filterPlan: function(filterBy) {
		this.set('hmoppoFilter', filterBy);
	},
	updateCarrierFilter: function(carrier) {
		this.set('carrierFilter', carrier);
	},
	updateSortProperty: function(newSortProp) {
		this.set('sortProperty', newSortProp);
	},
	updateSelectedSortProperty: function(newSortProp) {
		this.set('selectedSortProperty', newSortProp);
	},
	showAllPlans: function() {
		this.set('carrierFilter', "");
	},
	selectCategoryApplicable: function() {
		var isLargeGroup = this.get('company.isLargeGroup');
		if (isLargeGroup) {
			return true;
		}

		var state = this.get('company.state');
		if (state == 'NY' || state == 'OR') {
			return true;
		}

		var postACA = this.get('company.postACA');
		if (postACA) {
			return false;
		}

		var status = this.get('company.enrollmentStatus');
		if (status != 'complete' &&
			status != 'document' &&
			status != 'approved' &&
			(state == 'CA' || state == 'MA' || state == 'WA' || state == 'VA' || state == 'IL' || state == 'TX' || state == 'UT' || state == 'OH' || state == 'CO')) {
			return false;
		}

		if (status == 'approved') {
			var settings = this.get('company.settings');
			if (settings.get('postACA')) {
				return false;
			}
		}

		return true;
	}.property('company.state', 'company.enrollmentStatus', 'company.isLargeGroup', 'company.settings.postACA'),
	selectedPriceLevelChanged: function() {
		var priceLevel = this.get('selectedPriceLevel');
		this.updateSelectedSortProperty(priceLevel);
	}.observes('selectedPriceLevel'),
	priceLevelChanged: function() {
		var priceLevel = this.get('priceLevel');
		this.updateSortProperty(priceLevel);
	}.observes('priceLevel'),

	arePlansSelected: Ember.computed.gt('companyMedicalPlans.length', 0),

	_addPlanHelper: function(plan, differentCarrier, isShop) {
		var currentList;
		var planSavingPromises = [];
		if (this.get('initial')) {
			// Used by the 'planselector' URL
			currentList = this.get('companyMedicalPlans');

			var newItem = App.CompanyHealthPlan.createRecord();
			newItem.set('companyHealthCarrier', this.get('companyMedicalCarrier'));
			newItem.set('planId', plan.get('id'));
			planSavingPromises.push(newItem.save());

			var newHealthPlanObjects = this.get('companyMedicalHealthPlans');
			newHealthPlanObjects.pushObject(newItem);
		}
		else {
			// Used by the 'shop' URL
			currentList = this.get('selectedPlans');
			var newItem = App.ShoppingCartPlan.createRecord();
			newItem.set('plan', plan);
			newItem.set('company', this.get('company'));
			planSavingPromises.push(newItem.save());
		}

		// Add to selected list
		if (!currentList.contains(plan)) {
			currentList.pushObject(plan);
		}

		if (differentCarrier) {
			var planFilter = this.get('planFilter');
			if (!planFilter) {
				this.updateCarrierFilter(plan.get('carrier'));
			}
		}

		return Ember.RSVP.all(planSavingPromises).then(function() {
			var o = Ember.Object.create({
				companyMedicalCarrier: this.get('companyMedicalCarrier'),
				selectedPlans: currentList,
				isShop: isShop,
				addToCart: !this.get('initial')
			});
			return this.send('showModal', 'plan.added', o);
		}.bind(this));
	},
	differentCarrierHelper: function(plan, isShop) {
		this.send('hideModal');
		var carrier = plan.get('stateCarrier');
		var currentList;
		var planSavingPromises = [];
		if (this.get('initial')) {
			currentList = this.get('companyMedicalPlans');
		}
		else {
			currentList = this.get('selectedPlans');
			// remove all shopping cart plans
			var shoppingCartPlans = this.get('shoppingCartPlans');
			var existingItems = Ember.A();
			shoppingCartPlans.forEach(function (item) {
				existingItems.pushObject(item);
			});
			existingItems.forEach(function(item) {
				item.deleteRecord();
				planSavingPromises.push(item.save());
			});
		}

		var currentCarrier = this.get('companyMedicalCarrier');
		currentCarrier.set('carrier', plan.get('stateCarrier'));

		var currentMedicalHealthPlans = this.get('companyMedicalHealthPlans');
		currentMedicalHealthPlans.toArray().forEach(function(medicalHealthPlan) {
			medicalHealthPlan.deleteRecord();
			currentMedicalHealthPlans.removeObject(medicalHealthPlan);
			planSavingPromises.push(medicalHealthPlan.save());
		}.bind(this));

		currentList.toArray().forEach(function(plan) {
			currentList.removeObject(plan);
		});

		Ember.RSVP.all(planSavingPromises).then(function() {
			return this._addPlanHelper(plan, true, isShop);
		}.bind(this));
	},
	addPlanToPackage: function(plan, isShop) {
		this.send('hideModal');
		var self = this;
		var carrier = plan.get("stateCarrier");
		var plans;
		if (this.get('initial')) {
			plans = this.get('companyMedicalPlans');
		}
		else {
			plans = this.get('selectedPlans');
		}
		var existingCarrier = this.get('companyMedicalCarrier');
		if (existingCarrier && existingCarrier.get('carrier') != carrier && this.get('companyMedicalPlans.length') > 0) {
			// carrier not same display alert that the existing plans will be removed, and
			// the plan from the new carrier will be added remove all selected plans, add new plan
			var o = Ember.Object.create({
				plan: plan,
				existingCarrierName: existingCarrier.get('carrier.displayName'),
				addToCart: !this.get('initial'),
				carrierDifferent: true,
				blueCross: false,
				isShop: isShop
			});
			this.send('showModal', 'plan.addconfirm', o);
		}
		else if (plan && plan.get('isBlueCrossSelected') && plans && plans.get('length') && plans.objectAt(0).get('isBlueCrossSelected') && !blueCrossConstraintsSatisfied(plans, plan))
		{
			var o = Ember.Object.create({
				plan: plan,
				existingCarrierName: existingCarrier.get('carrier.displayName'),
				addToCart: !this.get('initial'),
				carrierDifferent: false,
				blueCross: true,
				isShop: isShop
			});
			this.send('showModal', 'plan.addconfirm', o);
		}
		else if(plan && plan.get('isCalChoiceSelected') && plans && (plans.get('length') > 0) && plans.objectAt(0).get('isCalChoiceSelected') &&
				calChoiceConstrains(plans, plan))
		{
			var o = Ember.Object.create({
				plan: plan,
				existingCarrierName: existingCarrier.get('carrier.displayName'),
				addToCart: !this.get('initial'),
				carrierDifferent: false,
				blueCross: false,
				isShop: isShop
			});
			this.send('showModal', 'plan.addconfirm', o);
		}
		else {
			if (carrier.get('displayName') == 'SeeChange') {
				var company = this.get('company');
				var employeeCount = company.get('employeeCount');
				if (employeeCount < 5) {
					if (this.get('initial') || this.get('selectedPlans')) {
						if (!this.get('initial')) {
							plan.set('addToCart', true);
						}
						this.send('showModal', 'plan.seechange', plan);
						return;
					}
				}
			}
			if (this.get('initial') && this.get('companyMedicalPlans.length') == 0) {
				if (existingCarrier) {
					existingCarrier.set('carrier', carrier);
					existingCarrier.save();
					this._addPlanHelper(plan, true, isShop);
				}
				else {
					var company = this.get('company');
					var chePromise = Ember.RSVP.resolve();
					if (!this.get('companyMedicalEnrollment')) {
						companyMedicalEnrollment = App.CompanyHealthEnrollment.createRecord();
						companyMedicalEnrollment.set('company', this.get('company'));
						companyMedicalEnrollment.set('lineOfCoverage', 'medical');
						companyMedicalEnrollment.set('enrollmentStatus', 'selected');
						companyMedicalEnrollment.set('enrollmentType', 'IE');
						companyMedicalEnrollment.set('startDate', formatDate(new Date()));
						companyMedicalEnrollment.set('isEnrollmentComplete', false);
						companyMedicalEnrollment.set('isActive', false);
						companyMedicalEnrollment.set('desiredEffectiveDate', zen.getFirstDayOfNextMonth());
						this.set('companyMedicalEnrollment', companyMedicalEnrollment);
						chePromise = companyMedicalEnrollment.save();
					}


					var companyMedicalEnrollment = this.get('companyMedicalEnrollment');
					chePromise.then(function() {
						var companyHealthCarrier = App.CompanyHealthCarrier.createRecord();
						companyHealthCarrier.set('company', company);
						companyHealthCarrier.set('companyHealthEnrollment', companyMedicalEnrollment);
						companyHealthCarrier.set('carrier', carrier);
						companyHealthCarrier.set('isPrimaryCarrier', true);
						return companyHealthCarrier.save();
					}).then(function(companyHealthCarrier) {
						self.set('companyMedicalCarrier', companyHealthCarrier);
						self._addPlanHelper(plan, true, isShop);
					});
				}
				return;
			}

			if (!this.get('initial') && this.get('selectedPlans.length') == 0) {
				this._addPlanHelper(plan, true, isShop);
				return;
			}
			this._addPlanHelper(plan, false, isShop);
		}
	},
	removeRow: function(plan) {
		var currentList;
		var planSavingPromises = [];
		if (this.get('initial')) {
			currentList = this.get('companyMedicalPlans');
			var planObject = null;
			var companyMedicalHealthPlans = this.get('companyMedicalHealthPlans');
			companyMedicalHealthPlans.forEach(function(medicalHealthPlan) {
				// sometimes these are CPlans and sometimes CPlanResourceApi
				// so do a id equals not a identity equals
				if (medicalHealthPlan.get('medicalPlan.id') == plan.get('id')) {
					planObject = medicalHealthPlan;
				}
			});
			if(planObject) {
				planObject.deleteRecord();
				planSavingPromises.push(planObject.save());
				companyMedicalHealthPlans.removeObject(planObject);
			}
		}
		else {
			currentList = this.get('selectedPlans');
			var shoppingCartPlans = this.get('shoppingCartPlans');
			var planObject;
			shoppingCartPlans.forEach(function(item) {
				if (item.get('plan') == plan) {
					planObject = item;
				}
			});
			planObject.deleteRecord();
			planSavingPromises.push(planObject.save());
		}

		this.set('isLoading', true);
		Ember.RSVP.all(planSavingPromises).then(function() {
			if(currentList.get('length')) {
				currentList.removeObject(plan);
			}
			if (currentList.length == 0) {
				this.showAllPlans();
			}
			this.set('isLoading', false);
		}.bind(this));
	},
	replaceSeeChangePlan: function(plan) {
		this.send('hideModal');
		var currentList;
		var planSavingPromises = [];
		if (this.get('initial')) {
			currentList = this.get('companyMedicalPlans');

			var currentMedicalHealthPlans = this.get('companyMedicalHealthPlans');
			var currentPlans = Ember.A();
			currentMedicalHealthPlans.forEach(function(currentMedicalHealthPlan) {
				currentPlans.pushObject(currentMedicalHealthPlan);
			});

			currentPlans.forEach(function(currentPlan) {
				currentPlan.deleteRecord();
				planSavingPromises.push(currentPlan.save());
			}.bind(this));

			var newItem = App.CompanyHealthPlan.createRecord();
			newItem.set('planId', plan.get('id'));
			newItem.set('companyHealthCarrier', this.get('companyMedicalCarrier'));
			var newHealthPlanObjects = Ember.A();
			newHealthPlanObjects.pushObject(newItem);
			planSavingPromises.push(newItem.save());
			this.set('companyMedicalHealthPlans', newHealthPlanObjects);

			if(currentList.get('length') > 0) {
				currentList.removeAt(0);
			}
			currentList.pushObject(plan);
			this.set('companyMedicalPlans', currentList);

			var currentCarrier = this.get('companyMedicalCarrier');
			currentCarrier.set('carrier', plan.get('stateCarrier'));
		}
		else {
			currentList = this.get('selectedPlans');
			var shoppingCartPlans = this.get('shoppingCartPlans');
			shoppingCartPlans.forEach(function(item) {
				item.deleteRecord();
				planSavingPromises.push(item.save());
			});
			var newItem = App.ShoppingCartPlan.createRecord();
			newItem.set('plan', plan);
			newItem.set('company', this.get('company'));
			planSavingPromises.push(newItem.save());

			currentList.removeAt(0);
			currentList.pushObject(plan);
		}

		Ember.RSVP.all(planSavingPromises).then(function() {
			$('html, body').animate({
				scrollTop: $("#selectedPlans").offset().top - 100
			}, 1000);
		});
	},

	actions: {
		enroll: function() {
			this.send('hideModal');
			if (this.get('initial')) {
				if (this.get('companyMedicalPlans.length') == 0) {
					this.set('errorText', 'Please select at least one medical plan to continue.');
					return Ember.RSVP.resolve();
				}

				var company = this.get('company');
				company.set('selectedCarrier', this.get('companyMedicalCarrier.carrier.name'));
				company.set('selectedStateCarrier', this.get('companyMedicalCarrier.carrier'));

				return this.saveAndContinue().then(function() {
					return this.transitionToRoute("dentalplanselector");
				}.bind(this));
			}
		},
	},
});

App.ShopController = App.SelectorController.extend({
	initial: false,
	selectedPlans: [],
	recommendedCarrier: "",
	includeFilter: "",
	alternateSelectCategoryApplicable: function() {
		var state = this.get('company.state');
		if (state == 'NY' || state == 'OR') {
			return true;
		}
		return false;
	}.property('company.state'),
	filteredAllPlans: function() {
		var hmoppoFilter = this.get('hmoppoFilter');
		var carrierFilter = this.get('carrierFilter');
		var includeFilter = this.get('includeFilter');
		var filteredContent;
		// RATES-352:
		// PPO tab: PPO or POS
		// HMO tab: HMO or EPO
		// HD tab: HD, HSA or HSA compatibility is True
		if (hmoppoFilter == 'PPO') {
			var ppoContent = this.filterProperty('HMOPPO', 'PPO');
			filteredContent = this.filterProperty('HMOPPO', 'POS');
			filteredContent.pushObjects(ppoContent);
		}
		if (hmoppoFilter == 'HMO') {
			var hmoContent = this.filterProperty('HMOPPO', 'HMO');
			filteredContent = this.filterProperty('HMOPPO', 'EPO');
			filteredContent.pushObjects(hmoContent);
		}
		if (hmoppoFilter == 'HD') {
			var hdContent = this.filterProperty('HMOPPO', 'HD');
			var hsaContent = this.filterProperty('HMOPPO', 'HSA');
			filteredContent = this.filterProperty('HSA', true);
			filteredContent.pushObjects(hdContent);
			filteredContent.pushObjects(hsaContent);
		}
		else {
			filteredContent = this.filterProperty('HMOPPO', hmoppoFilter);
		}

		if (carrierFilter) {
			filteredContent = filteredContent.filterProperty('carrier', carrierFilter);
		}

		// This is used for change plans
		if (includeFilter) {
			filteredContent.toArray().forEach(function(item) {
				if (item.get('carrier') != includeFilter) {
					filteredContent.removeObject(item);
				}
			});
		}

		var selectedPlanIds = this.get('selectedPlans').mapBy('id');
		if (selectedPlanIds) {
			filteredContent = filteredContent.filter( function(item) {
				return !(selectedPlanIds.contains(item.get('id')));
			});
		}

		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: [this.get('sortProperty')],
			content: filteredContent
		});
	}.property('hmoppoFilter', 'carrierFilter', 'includeFilter', 'selectedPlans.@each', 'sortProperty'),
});

App.PackageSizeValidatorMixin = Ember.Mixin.create({
	_loc: Ember.computed(function() { //capitalized for use in longer names
		return Ember.String.capitalize(this.get('lineOfCoverage'));
	}).property('lineOfCoverage'),

	companyCarrier: Ember.computed.alias('companyHealthCarrier.carrier'),
	companyHealthCarrier: Ember.computed(function() {
		return this.get('company' + this.get('_loc') + 'Carrier');
	}).property('companyMedicalCarrier', 'companyDentalCarrier', 'companyVisionCarrier', '_loc'),

	companyPlanCount: Ember.computed(function() {
		return this.get('company' + this.get('_loc') + 'Plans.length');
	}).property('companyMedicalPlans.length', 'companyDentalPlans.length', 'companyVisionPlans.length', '_loc'),

	synchronizedMaxPlans: 0, //Updated every time maxPlans updates.

	skipValidations: Ember.computed(function() { // Synchronous whitelist conditions go here.
		if (this.get('company' + this.get('_loc') + 'Carrier.skipPackageSizeValidation')) {
			return true; // Flag set in console on the CHE, let the client do as they please.
		}
		var companyPlans = this.get('company' + this.get('_loc') + 'Plans');
		return companyPlans.some(function(companyPlan) { // If any custom plans, don't check package size.
			return companyPlan.get('plan.customPlan');
		});
	}).property('companyMedicalPlans.[]', 'companyDentalPlans.[]', 'companyVisionPlans.[]', '_loc'),

	defaultMaxPlans: Ember.computed(function() { //If we don't have an applicable rule in the database.
		var lineOfCoverage = this.get('lineOfCoverage');
		if (this.get('company.employeeCount') <= 5 && (lineOfCoverage === 'dental' || lineOfCoverage === 'vision')) {
			return 1; //SSB team wants this default.
		}
		return 999; //No limit.
	}).property('lineOfCoverage', 'company.employeeCount'),

	maxPlans: Ember.computed(function() {
		var validPackageSizes = [];

		var defaultMaxplans = this.get('defaultMaxPlans');


		if (this.get('companyCarrier.id')) {
			validPackageSizes = App.PackageSize.find({
				q_isSearch: true,
				q_lineOfCoverage: this.get('lineOfCoverage'),
				q_carrierId: this.get('companyCarrier.id'),
				q_numEmployees: this.get('company.employeeCount'),
				q_effectiveDate: this.get('companyHealthCarrier.companyHealthEnrollment.desiredEffectiveDate'),
			});
		} else {
			this.set('synchronizedMaxPlans', defaultMaxplans);
			return Ember.RSVP.resolve(defaultMaxplans);
		}
		var self = this;

		return validPackageSizes.then(function(packageSizes) {
			var maxplans = 0;

			packageSizes.forEach(function(packageSize) {
				maxplans = Math.max(maxplans, packageSize.get('numberOfPlans')); //Take the most generous rule if any rules are found.
			});
			if (!packageSizes.get('length')) {
				maxplans = defaultMaxplans;
			}
			self.set('synchronizedMaxPlans', maxplans);
			return maxplans;
		});
	}).property('companyCarrier', 'company.employeeCount', 'lineOfCoverage', 'defaultMaxPlans', 'skipValidations'),

	packageFlaw: Ember.computed(function() { //A promise of what is wrong with package.
		var self = this;
		if (this.get('skipValidations')) {
			return Ember.RSVP.resolve(""); // Package is whitelisted; no flaw.
		}
		return this.get('maxPlans').then(function(maxPlans) {
			var numPlans = self.get('companyPlanCount');
			var theCarrierName = self.get('companyCarrier.name') || "Your carrier";
			var flaw = "";

			if (self.get('lineOfCoverage') === 'medical' && numPlans === 0) {
				flaw = 'Please select at least one medical plan to continue.';
			} else if (numPlans > maxPlans) {
				flaw = (theCarrierName + ' allows no more than ' + maxPlans + ' ' + self.get('lineOfCoverage') +
					' ' +	(maxPlans == 1 ? 'plan' : 'plans') + ' for your number of employees.');
			}
			return flaw;
		});
	}).property('maxPlans', 'companyPlanCount', 'companyCarrier.name', 'skipValidations'),

	arePlansValid: Ember.computed(function() {
		var self = this;
		this.get('packageFlaw').then(function(flaw_value) {
			self.set('errorText', flaw_value);
		});
		return this.get('errorText') === ""; // No error
	}).property('errorText', 'packageFlaw', 'companyPlanCount', 'companyCarrier'),
	//Depends on 'errorText' to catch asynchronous updates.

	actions: {
		enroll: function() {
			//Does not fulfill ShopController requests (i.e. initial === false)
			if (this.get('initial') !== false) {
				var self = this;
				return this.get('packageFlaw').then(function(flaw) {
					self.set('errorText', flaw);
					if (flaw) { //Show flaw modal and don't continue enrollment
						var o = Ember.Object.create({
							arePlansSelected: self.get('companyPlanCount') > 0,
							carrier: self.get('companyCarrier'),
							maxPlans: self.get('synchronizedMaxPlans'),
							lineOfCoverage: self.get('lineOfCoverage'),
							planSingularOrPlural: self.get('synchronizedMaxPlans') == 1 ? 'plan' : 'plans'
						});
						self.send('showModal', 'packagesize.dialog', o);
					}
					else {
						return self.continueEnrollment();
					}
				});
			}
		},
	},
});

App.PlansreviewController = App.SelectorController.extend(App.PackageSizeValidatorMixin, {
	initial: true, // This is overridden in ShopController
	review: true,

	isNy: Ember.computed.equal('company.state', 'NY'),
	displayDisclaimer: Ember.computed.not('isNy'),

	localCarrierChange: function() {
		return (this.get('prevMedicalCarrier') != this.get('companyMedicalCarrier.carrier'));
	}.property('companyMedicalCarrier', 'companyMedicalCarrier.carrier', 'prevMedicalCarrier'),
	localPlanChange: function() {
		return (this.get('prevMedicalPlans').toArray().sort().toString() != this.get('companyMedicalPlans').toArray().sort().toString());
	}.property('companyMedicalPlans', 'companyMedicalPlans.@each', 'prevMedicalPlans'),
	packageChanged : Ember.computed.or('localCarrierChange', 'localPlanChange'),
	revertChanges : function() {
		window.location.reload();
	},
	continueEnrollment: function() {
		this.send('hideModal');
		var carrierChange = false;
		var planChange = false;
		var saveText;
		var showContinue = this.get('transitionToDental') || this.get('transitionToVision');

		var continueText;
		if (this.get('transitionToDental')) {
			continueText = "Continue to Dental";
		}
		else if (this.get('transitionToVision')) {
			continueText = "Continue to Vision";
		}

		if (this.get('companyMedicalPlans.length') == 0) {
			this.set('errorText', 'Please select at least one medical plan to continue.');
			return Ember.RSVP.reject();
		}

		if (this.get('companyMedicalEnrollment').get('isReviewing') || this.get('prevMedicalCarrier') != this.get('companyMedicalCarrier.carrier')) {
			carrierChange = true;
			var company = this.get('company');
			company.set('selectedCarrier', this.get('companyMedicalCarrier.carrier.name'));
			company.set('selectedStateCarrier', this.get('companyMedicalCarrier.carrier'));
			saveText = 'Sign Agreement';
		}
		else if(this.get('prevMedicalPlans').toArray().sort().toString() != this.get('companyMedicalPlans').toArray().sort().toString()) {
			planChange = true;
			saveText = 'Send E-mails';
		}

		if (carrierChange || planChange) {
			var o = Ember.Object.create({
				carrierChange : carrierChange,
				planChange : planChange,
				lineOfCoverage: 'medical',
				saveText: saveText,
				cancelText: "Cancel Change",
				carrier: this.get('companyMedicalCarrier.carrier'),
				showContinue: showContinue && carrierChange,
				continueText: continueText
			});
			this.send('showModal', 'review.dialog', o);
		}
		else {
			return this.handleTransitions();
		}
	},
	save: function(carrierChange, signAgreement) {
		if (carrierChange) {
				this.get('companyMedicalEnrollment').set('isActive', false);
				this.get('companyMedicalEnrollment').set('isEnrollmentComplete', false);
		}
		this.saveAndContinue().then(function() {
			var localPromiseArray = [];
			localPromiseArray.push(this.get('companyEnrollmentReviewApi').reload());
			localPromiseArray.push(this.get('company').reload());
			Ember.RSVP.all(localPromiseArray).then(function() {
				var action = 'carrierchange';
				var self = this;
				if(!carrierChange) {
					action = 'planchange';
				}

				$.ajax({
					url: '/custom_api/plansReview?action=' + action + '&lineOfCoverage=medical&enrollmentType=' + this.get('companyMedicalEnrollment').get('enrollmentType'),
					type: "post",
					success: function(response) {
						self.send("hideModal");
						if(carrierChange && signAgreement) {
							self.transitionToRoute('reviewfinish');
						}
						else {
							self.handleTransitions();
						}
					},
					error: function(jqXHR, textStatus, errorThrown) {
					}
				});
			}.bind(this));
		}.bind(this));
	},
	handleTransitions : function() {
		if(this.get('transitionToDental')) {
			this.transitionToRoute('dentalplansreview');
		}
		else if(this.get('transitionToVision')) {
			this.transitionToRoute('visionplansreview');
		}
		else {
			this.transitionToRoute('index');
		}
	}
});


App.ReviewDialogController = Ember.ObjectController.extend({
	reviewController: null,
	save : function() {
		this.get('reviewController').save(this.get('carrierChange'), false);
	},
	signAgreement : function() {
		this.get('reviewController').save(this.get('carrierChange'), true);
	},
	cancel: function() {
		this.send('hideModal');
	}
});

App.PackagesizeDialogController = Ember.ObjectController.extend({
	reviewController: null,

	cancel: function() {
		$('html, body').animate({
			scrollTop: $("#selectedPlans").offset().top - 100
		}, 500);
		this.send('hideModal');
	}
});


App.PlanWhytheseController = Ember.ObjectController.extend({
	cancel: function() {
		this.send('hideModal');
	}
});


App.PlanOverviewController = Ember.ObjectController.extend({
	needs: ['planselector', 'shop'],
	shopController: Ember.computed.alias("controllers.shop"),
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.planselector');
	}.property('selectorControllerOverride'),
	addPlanToPackage: function(plan, isShop) {
		var selector;
		if (plan.get('addToCart')) {
			selector = this.get('shopController');
		}
		else {
			selector = this.get('selectorController');
		}
		selector.addPlanToPackage(plan, isShop);
	},
	cancel: function() {
		this.send('hideModal');
	}
});


App.PlanAddedController = Ember.ObjectController.extend({
	needs: ['planselector', 'shop'],
	shopController: Ember.computed.alias("controllers.shop"),
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.planselector');
	}.property('selectorControllerOverride'),

	actions: {
		enroll: function() {
			var selector;
			if (this.get('addToCart')) {
				selector = this.get('shopController');
			}
			else {
				selector = this.get('selectorController');
			}

			return selector.send('enroll');
		},
	},

	cancel: function() {
		$('html, body').animate({
			scrollTop: $("#selectedPlans").offset().top - 100
		}, 500);
		this.send('hideModal');
	}
});


App.PlanAddconfirmController = Ember.ObjectController.extend({
	needs: ['planselector', 'shop'],
	shopController: Ember.computed.alias("controllers.shop"),
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.planselector');
	}.property('selectorControllerOverride'),
	differentCarrierHelper: function(plan, isShop) {
		var selector;
		if (this.get('addToCart')) {
			selector = this.get('shopController');
		}
		else {
			selector = this.get('selectorController');
		}

		selector.differentCarrierHelper(plan, isShop);
	},
	cancel: function() {
		this.send('hideModal');
	}
});


App.PlanSeechangeController = Ember.ObjectController.extend({
	needs: ['planselector', 'shop'],
	shopController: Ember.computed.alias("controllers.shop"),
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.planselector');
	}.property('selectorControllerOverride'),
	replaceSeeChangePlan: function(plan) {
		var selector;
		if (plan.get('addToCart')) {
			selector = this.get('shopController');
		}
		else {
			selector = this.get('selectorController');
		}
		selector.replaceSeeChangePlan(plan);
	},
	cancel: function() {
		this.send('hideModal');
	}
});


if (window.document) {
	// Dialogs
	$('.page').on('click', '.dialog-link', function(e){
		e.preventDefault();
		var dialog = $(this).attr('data-dialog');
		if (dialog != '') {
			$('body').append('<div class="dialog-bg" />');
			$('.dialog.'+dialog)
				.addClass('visible')
				.css("margin-top", $("body").scrollTop() + "px")
				.fadeIn();
		}
	});

	$('.page').on('click', '.dialog a.close', function(e){
		e.preventDefault();
		$('.dialog, .dialog-bg').fadeOut();
		$('.dialog-bg').remove();
		$('.dialog').removeClass('visible');
	});

	$('.page').on('click', '.dialog a.cancel', function(e){
		e.preventDefault();
		$('.dialog, .dialog-bg').fadeOut();
		$('.dialog-bg').remove();
		$('.dialog').removeClass('visible');
	});

	$('.page').on('click', '.plan-why-tab', function(e) {
		e.preventDefault();
		var why = $(this).prev('.plan-why');
		if ($(why).is(':visible')) {
			$(why).slideUp();
		} else {
			$(why).slideDown();
		}
		$(this).toggleClass('open');
	});

	$('.page').on('click', '.alternatives .tab', function(e) {
		e.preventDefault();
		var tab = $(this);
		var tabs = tab.closest('.tabs');
		tabs.find('.tab').removeClass('current');
		tab.addClass('current');
	});

	$('.page').on('click', '.quotes .show-alternatives', function(e) {
		e.preventDefault();
		$(this).toggleClass('open');
		if ($('.alternatives').is(':visible')) {
			$('.alternatives').hide();
			$(this).html('Show alternative plans');
			$('html, body').animate({
				scrollTop: $(".current").offset().top - 100
			}, 500);
		}
		else {
			$('.alternatives').show();
			$(this).html('Hide alternative plans');
			$('html, body').animate({
				scrollTop: $(".alternatives").offset().top - 100
			}, 500);
		}
	});

	$('.page').on('click', '.quotes .filter', function(e) {
		e.preventDefault();
		$(this).toggleClass('open');
		if ($('.filters').is(':visible')) {
			$('.filters').slideUp();
			$(this).html('Filter Plans');
		}
		else {
			$('.filters').slideDown();
			$(this).html('Hide Filters');
		}
	});

	// Employer Dashboard
	$('.page').on({
		mouseenter: function () {
			$('.button', this).removeClass('grey');
		},
		mouseleave: function () {
			$('.button', this).addClass('grey');
		}
	}, '.orange-button-hover-block');
}

//Helpers
App.NumberCheckHelper = Ember.HTMLBars.makeBoundHelper(function(params, hash) {
	var value = params[0];
	if (value == 0) {
		return "No";
	}

	var full = hash.enrollmentSectionController.get('currentSection').get('subsections').get('length');
	if (value == full) {
		return "All";
	}

	return value;
});

App.SubsectionsCompletedHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var section = params[0];
	var completed = 0;
	if (!section.get('subsections')) {
		return completed;
	}
	section.get('subsections').forEach(function(obj) {
		if (obj.get('isCompleted')) {
			completed++;
		}
	});

	return completed;
});


App.CurrentSubsectionPosHelper = Ember.HTMLBars.makeBoundHelper(function(params, hash) {
	var section = params[0];
	var index = 1;
	var currentSubsection = hash.enrollmentSectionController.get('currentSubsection');
	if (!section) {
		return index;
	}
	index = section.get('orderedSubsections').indexOf(currentSubsection) + 1;

	return index;
});

App.AttributePresentHelper = Ember.HTMLBars.makeBoundHelper(function(params, hash) {
	// Or, we can use this.container.lookup('controller:enrollmentSection').
	// Injection into helpers is apparently no longer supported as of 1.11.1.
	var name = params[0];
	var currentSubsection = hash.enrollmentSectionController.get('currentSubsection');
	var attrs = currentSubsection.get('attrList');
	return ~attrs.indexOf(name);
});



function showAllPlans(event) {
	$('#allPlans').show();
	$('#explore').text("Hide alternative plans");
	$('html, body').animate({
		scrollTop: $("#allPlans").offset().top - 100
	}, 500);
}


/*
 * DENTAL PLAN SELECTOR
 */
App.DentalplanselectorHelper = _TransactionArraySavingController.extend(_PlanSelectorPremiumTierMixin, {
	lineOfCoverage: 'dental',
	sortProperties: ['recommended', 'avgYouPremium'],
	sortAscending: false,
	sortAscendingProperties: ['avgYouPremium'],
	selectedSortProperty: ['avgYouPremium'],
	priceLevel: 'avgYouPremium',
	hmoppoFilter: 'PPO',
	excludeFilter: '',
	carrierFilter: '',
	hasCarrier: Ember.computed.bool('companyDentalCarrier.carrier'),
	dentalCarrier: function() {
		var selectedDentalPlans = this.get('company.selectedDentalPlans');

		return selectedDentalPlans.find(function(plan) {
			return plan.get('carrier');
		});
	}.property('company.selectedDentalPlans.@each.carrier'),
	arePlansSelected: function() {
		if (this.get('companyDentalPlans.length') > 0) {
			this.set('sortProperties', ['avgYouPremium']);
			return true;
		}

		this.set('sortProperties', ['recommended', 'avgYouPremium']);
		return false;
	}.property('companyDentalPlans.length'),
	filteredAllPlans: function() {
		var hmoppoFilter = this.get('hmoppoFilter');
		var excludeFilter = this.get('excludeFilter');
		var carrierFilter = this.get('carrierFilter');
		var filteredContent;
		if (hmoppoFilter == 'PPO') {
			var ppoContent = this.filterProperty('HMOPPO', 'PPO');
			filteredContent = this.filterProperty('HMOPPO', 'EPO');
			filteredContent.pushObjects(ppoContent);
		}
		else {
			filteredContent = this.filterProperty('HMOPPO', hmoppoFilter);
		}

		// This is used for switch carrier.
		if (excludeFilter) {
			filteredContent.toArray().forEach(function(item) {
				if (item.get('carrier') == excludeFilter) {
					filteredContent.removeObject(item);
				}
			});
		}

		if (carrierFilter) {
			filteredContent = filteredContent.filterProperty('carrier', carrierFilter);
		}


		var selectedPlanIds = this.get('companyDentalPlans').mapBy('id');
		if (selectedPlanIds) {
			filteredContent = filteredContent.filter( function(item) {
				return !(selectedPlanIds.contains(item.get('id')));
			});
		}

		return Ember.ArrayProxy.createWithMixins(Ember.MultiSortableMixin, {
			sortProperties: this.get('sortProperties'),
			sortAscending: this.get('sortAscending'),
			sortAscendingProperties: this.get('sortAscendingProperties'),
			content: filteredContent
		});
	}.property('hmoppoFilter', 'excludeFilter', 'carrierFilter', 'companyDentalPlans.@each', 'sortProperties', 'sortAscending', 'sortAscendingProperties'),
	sortedSelectedPlans: function() {
		var selectedPlans = this.get('companyDentalPlans');
		if (selectedPlans) {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: this.get('selectedSortProperty'),
				content: selectedPlans
			});
		}

		return selectedPlans;
	}.property('companyDentalPlans', 'selectedSortProperty'),
	filterPlan: function(filterBy) {
		this.set('hmoppoFilter', filterBy);
	},
	updateCarrierFilter: function(carrier) {
		this.set('carrierFilter', carrier);
	},
	showAllPlans: function() {
		this.set('carrierFilter', '');
	},
	planOverview: function(plan) {
		var o = Ember.Object.create({
			plan: plan,
			company: this.get('company')
		});
		this.send('showModal', 'dentalplanoverview', o);
	},
	skip: function() {
		var companyDentalHealthPlans = this.get('companyDentalHealthPlans');
		if (companyDentalHealthPlans) {
			companyDentalHealthPlans.toArray().forEach(function(companyDentalHealthPlan) {
				companyDentalHealthPlan.deleteRecord();
				companyDentalHealthPlans.removeObject(companyDentalHealthPlan);
			}.bind(this));
		}

		var companyDentalCarrier = this.get('companyDentalCarrier');
		if (companyDentalCarrier) {
			companyDentalCarrier.deleteRecord();
		}

		var companyDentalEnrollment = this.get('companyDentalEnrollment');
		if (companyDentalEnrollment) {
			companyDentalEnrollment.deleteRecord();
		}
	},
	removeRow: function(plan) {
		var companyDentalHealthPlans = this.get('companyDentalHealthPlans');
		var removedPlanPromises = companyDentalHealthPlans.toArray().filterBy('dentalPlan', plan)
		.map(function(companyDentalHealthPlan) {
			companyDentalHealthPlans.removeObject(companyDentalHealthPlan);
			return companyDentalHealthPlan.destroyRecord();
		});

		// Update plans
		var currentList = this.get('companyDentalPlans');
		currentList.removeObject(plan);
		if (currentList.length == 0) {
			this.showAllPlans();
		}

		return Ember.RSVP.all(removedPlanPromises);
	},
	differentCarrierConfirmation: function(plan) {
		var carrier = plan.get('stateCarrier');
		var existingCarrier = this.get('companyDentalCarrier');
		var o = Ember.Object.create({
			plan: plan,
			existingCarrier: existingCarrier,
			existingCarrierName: existingCarrier.get('carrier.displayName')
		});
		this.send('showModal', 'dentalplanselector.addconfirm', o);
	},
	addPlanToPackage: function(plan, existingCarrier) {
		var companyDentalHealthPlans = this.get('companyDentalHealthPlans');
		var planSavingPromises = [];

		// Check if a plan was previously selected
		if (existingCarrier.get('carrier') != plan.get('stateCarrier')) {
			// Change carrier
			existingCarrier.set('carrier', plan.get('stateCarrier'));
			this.updateCarrierFilter(plan.get('carrier'));

			// Delete plans on server and update health plan objects on client side
			companyDentalHealthPlans.toArray().forEach(function(companyDentalHealthPlan) {
				companyDentalHealthPlans.removeObject(companyDentalHealthPlan);
				planSavingPromises.push(companyDentalHealthPlan.destroyRecord());
			}.bind(this));
			// Delete selected plans on client side
			var dentalPlans = this.get('companyDentalPlans');
			dentalPlans.toArray().forEach(function(dentalPlan) {
				dentalPlans.removeObject(dentalPlan);
			});
		}

		/* Create new plan and link to carrier */
		var newPlan = App.CompanyHealthPlan.createRecord();
		newPlan.set('companyHealthCarrier', this.get('companyDentalCarrier'));
		newPlan.set('planId', plan.get('id'));

		/* Update selected plans on client side */
		this.get('companyDentalHealthPlans').pushObject(newPlan);
		this.get('companyDentalPlans').pushObject(plan);

		planSavingPromises.push(newPlan.save());
		return Ember.RSVP.all(planSavingPromises);

	}
});

App.DentalplansreviewController = App.DentalplanselectorHelper.extend(App.PackageSizeValidatorMixin, {
	review: true,
	localCarrierChange: function() {
		return (this.get('prevDentalCarrier') != this.get('companyDentalCarrier.carrier'));
	}.property('companyDentalCarrier', 'companyDentalCarrier.carrier', 'prevDentalCarrier'),
	localPlanChange: function() {
		return (this.get('prevDentalPlans').toArray().sort().toString() != this.get('companyDentalPlans').toArray().sort().toString());
	}.property('companyDentalPlans', 'companyDentalPlans.@each', 'prevDentalPlans'),
	packageChanged : function() {
		return (this.get('localCarrierChange') || this.get('localPlanChange'));
	}.property('localCarrierChange', 'localPlanChange'),
	skip: function() {
		App.store.get('defaultTransaction').rollback();
		this.handleTransitions();
	},
	goBackToMedical: function() {
		return this.get('transitionToMedical');
	}.property('transitionToMedical'),
	goBack: function() {
		App.store.get('defaultTransaction').rollback();
		if(this.get('goBackToMedical')) {
			this.transitionToRoute('plansreview');
		}
		else {
			this.transitionToRoute("index");
		}
	},
	revertChanges : function() {
		window.location.reload();
	},
	continueEnrollment: function() {
		this.send('hideModal');
		var carrierChange = false;
		var planChange = false;
		var saveText;
		var showContinue = this.get('transitionToVision');

		var continueText;
		if (this.get('transitionToVision')) {
			continueText = "Continue to Vision";
		}

		if (this.get('companyDentalPlans.length') == 0) {
			this.set('errorText', 'Please select at least one dental plan to continue.');
			return Ember.RSVP.reject();
		}

		if (this.get('companyDentalEnrollment').get('isReviewing') || this.get('prevDentalCarrier') != this.get('companyDentalCarrier.carrier')) {
			carrierChange = true;
			saveText = 'Sign Agreement';
		}
		else if(this.get('prevDentalPlans').toArray().sort().toString() != this.get('companyDentalPlans').toArray().sort().toString()) {
			planChange = true;
			saveText = 'Send E-Mails';
		}

		if (carrierChange || planChange) {
			var o = Ember.Object.create({
				carrierChange : carrierChange,
				planChange : planChange,
				lineOfCoverage: 'dental',
				saveText: saveText,
				cancelText: "Cancel Change",
				carrier: this.get('companyDentalCarrier.carrier'),
				showContinue: showContinue && carrierChange,
				continueText: continueText,
			});
			this.send('showModal', 'review.dialog', o);
		}
		else {
			return this.handleTransitions();
		}
	},
	save: function(carrierChange, signAgreement) {
		if(carrierChange) {
				this.get('companyDentalEnrollment').set('isActive', false);
				this.get('companyDentalEnrollment').set('isEnrollmentComplete', false);
		}

		this.saveAndContinue().then(function() {
			var localPromiseArray = [];
			localPromiseArray.push(this.get('companyEnrollmentReviewApi').reload());
			localPromiseArray.push(this.get('company').reload());
			Ember.RSVP.all(localPromiseArray).then(function() {
				var action = 'carrierchange';
				var self = this;
				if(!carrierChange) {
					action = 'planchange';
				}

				$.ajax({
					url: '/custom_api/plansReview?action=' + action + '&lineOfCoverage=dental&enrollmentType=' + this.get('companyDentalEnrollment').get('enrollmentType'),
					type: "post",
					success: function(response) {
						self.send("hideModal");
						if (carrierChange && signAgreement) {
							self.transitionToRoute('reviewfinish');
						}
						else {
							self.handleTransitions();
						}
					},
					error: function(jqXHR, textStatus, errorThrown) {
					}
				});
			}.bind(this));
		}.bind(this));
	},
	createPlan: function(plan) {
		var companyDentalCarrier = this.get('companyDentalCarrier');

		if(companyDentalCarrier.get('carrier') != plan.get('stateCarrier') && this.get('companyDentalPlans.length')) {
			this.differentCarrierConfirmation(plan);
		}
		else {
			companyDentalCarrier.set('carrier', plan.get('stateCarrier'));
			this.addPlanToPackage(plan, companyDentalCarrier);
		}
	},
	addPlanToPackage: function(plan, companyDentalCarrier) {
		this._super(plan, companyDentalCarrier);

		$('html, body').animate({
			scrollTop: $("#dentalHeader").offset().top - 200
		}, 500);
		this.send('hideModal');
	},
	handleTransitions : function(carrierChange) {
		if(this.get('transitionToVision')) {
			this.transitionToRoute('visionplansreview');
		}
		else if (this.get('companyMedicalEnrollment') && this.get('companyMedicalEnrollment').get('isReviewing')){
			this.transitionToRoute('reviewfinish');
		}
		else {
			this.transitionToRoute('index');
		}
	}
});


App.DentalplanselectorView = Ember.View.extend({
	didInsertElement: function() {
		$('a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('class="note orange"') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});


App.DentalplanoverviewController = _TransactionSavingController.extend({
	needs: 'dentalplanselector',
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.dentalplanselector');
	}.property('selectorControllerOverride'),
	createPlan: function(plan) {
		var selector = this.get('selectorController');
		selector.createPlan(plan);
	},
	cancel: function() {
		this.send('hideModal');
	}
});


App.DentalplanselectorAddconfirmController = Ember.ObjectController.extend({
	needs: 'dentalplanselector',
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.dentalplanselector');
	}.property('selectorControllerOverride'),
	addPlanToPackage: function(plan, existingCarrier) {
		var selector = this.get('selectorController');
		selector.addPlanToPackage(plan, existingCarrier);
	}
});


App.DentalplanselectorErrorController = Ember.ObjectController.extend();


/*
 * VISION PLAN SELECTOR
 */
App.VisionplanselectorHelper = _TransactionArraySavingController.extend(_PlanSelectorPremiumTierMixin, {
	lineOfCoverage: 'vision',
	sortProperties: ['recommended', 'avgYouPremium'],
	sortAscending: false,
	sortAscendingProperties: ['avgYouPremium'],
	selectedSortProperty: ['avgYouPremium'],
	priceLevel: 'avgYouPremium',
	excludeFilter: '',
	carrierFilter: '',
	hasCarrier: Ember.computed.bool('companyVisionCarrier.carrier'),
	visionCarrier: function() {
		var selectedVisionPlans = this.get('company.selectedVisionPlans');
		var visionCarrier = '';
		selectedVisionPlans.forEach(function(plan) {
			visionCarrier = plan.get('carrier');
		});

		return visionCarrier;
	}.property('company.selectedVisionPlans.@each.carrier'),
	arePlansSelected: function() {
		if (this.get('companyVisionPlans.length')) {
			this.set('sortProperties', ['avgYouPremium']);
			return true;
		}

		this.set('sortProperties', ['recommended', 'avgYouPremium']);
		return false;
	}.property('companyVisionPlans.length'),
	filteredAllPlans: function() {
		var filteredContent = this.get('content').toArray();
		var excludeFilter = this.get('excludeFilter');
		var carrierFilter = this.get('carrierFilter');

		// This is used for switch carrier.
		if (excludeFilter) {
			filteredContent.toArray().forEach(function(item) {
				if (item.get('carrier') == excludeFilter) {
					filteredContent.removeObject(item);
				}
			});
		}

		if (carrierFilter) {
			filteredContent = this.filterProperty('carrier', carrierFilter);
		}

		var selectedPlanIds = this.get('companyVisionPlans').mapBy('id');
		if (selectedPlanIds) {
			filteredContent = filteredContent.filter( function(item) {
				return !(selectedPlanIds.contains(item.get('id')));
			});
		}

		return Ember.ArrayProxy.createWithMixins(Ember.MultiSortableMixin, {
			sortProperties: this.get('sortProperties'),
			sortAscending: this.get('sortAscending'),
			sortAscendingProperties: this.get('sortAscendingProperties'),
			content: filteredContent
		});
	}.property('content.@each', 'excludeFilter', 'carrierFilter', 'companyVisionPlans.@each', 'sortProperties', 'sortAscending', 'sortAscendingProperties'),
	sortedSelectedPlans: function() {
		var selectedPlans = this.get('companyVisionPlans');
		if (selectedPlans) {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: this.get('selectedSortProperty'),
				content: selectedPlans
			});
		}

		return selectedPlans;
	}.property('companyVisionPlans', 'selectedSortProperty'),
	updateCarrierFilter: function(carrier) {
		this.set('carrierFilter', carrier);
	},
	showAllPlans: function() {
		this.set('carrierFilter', '');
	},
	planOverview: function(plan) {
		var o = Ember.Object.create({
			plan: plan,
			company: this.get('company')
		});
		this.send('showModal', 'visionplanoverview', o);
	},
	skip: function() {
		var companyVisionHealthPlans = this.get('companyVisionHealthPlans');
		if (companyVisionHealthPlans) {
			companyVisionHealthPlans.toArray().forEach(function(companyVisionHealthPlan) {
				companyVisionHealthPlan.deleteRecord();
				companyVisionHealthPlans.removeObject(companyVisionHealthPlan);
			}.bind(this));
		}

		var companyVisionCarrier = this.get('companyVisionCarrier');
		if (companyVisionCarrier) {
			companyVisionCarrier.deleteRecord();
		}

		var companyVisionEnrollment = this.get('companyVisionEnrollment');
		if (companyVisionEnrollment) {
			companyVisionEnrollment.deleteRecord();
		}
	},

	removeRow: function(plan) {
		var companyVisionHealthPlans = this.get('companyVisionHealthPlans');
		var removedPlanPromises = companyVisionHealthPlans.toArray().filterBy('visionPlan', plan)
		.map(function(companyVisionHealthPlan) {
			companyVisionHealthPlans.removeObject(companyVisionHealthPlan);
			return companyVisionHealthPlan.destroyRecord();
		});

		// Update selected plans on client side
		var currentList = this.get('companyVisionPlans');
		currentList.removeObject(plan);
		if (currentList.length == 0) {
			this.showAllPlans();
		}

		return Ember.RSVP.all(removedPlanPromises);
	},
	differentCarrierConfirmation: function(plan) {
		var carrier = plan.get('stateCarrier');
		var existingCarrier = this.get('companyVisionCarrier');
		var o = Ember.Object.create({
			plan: plan,
			existingCarrier: existingCarrier,
			existingCarrierName: existingCarrier.get('carrier.displayName')
		});
		this.send('showModal', 'visionplanselector.addconfirm', o);
	},
	addPlanToPackage: function(plan, existingCarrier) {
		var companyVisionHealthPlans = this.get('companyVisionHealthPlans');
		var planSavingPromises = [];

		if (existingCarrier.get('carrier') != plan.get('stateCarrier')) {
			// Change carrier
			existingCarrier.set('carrier', plan.get('stateCarrier'));
			this.updateCarrierFilter(plan.get('carrier'));

			// Delete plans on server and update health plan objects on client side
			companyVisionHealthPlans.toArray().forEach(function(companyVisionHealthPlan) {
				companyVisionHealthPlans.removeObject(companyVisionHealthPlan);
				planSavingPromises.push(companyVisionHealthPlan.destroyRecord());
			}.bind(this));

			// Delete selected plans on client side
			var companyVisionPlans = this.get('companyVisionPlans');
			companyVisionPlans.toArray().forEach(function(visionPlan) {
				companyVisionPlans.removeObject(visionPlan);
			});
		}

		/* Create new plan and link to carrier */
		var newPlan = App.CompanyHealthPlan.createRecord();
		newPlan.set('companyHealthCarrier', this.get('companyVisionCarrier'));
		newPlan.set('planId', plan.get('id'));

		/* Update selected plans on client side */
		this.get('companyVisionHealthPlans').pushObject(newPlan);
		this.get('companyVisionPlans').pushObject(plan);

		planSavingPromises.push(newPlan.save());
		return Ember.RSVP.all(planSavingPromises);
	}
});

App.VisionplansreviewController = App.VisionplanselectorHelper.extend(App.PackageSizeValidatorMixin, {
	review: true,
	localCarrierChange: function() {
		return (this.get('prevVisionCarrier') != this.get('companyVisionCarrier.carrier'));
	}.property('companyVisionCarrier', 'companyVisionCarrier.carrier', 'prevVisionCarrier'),
	localPlanChange: function() {
		return (this.get('prevVisionPlans').toArray().sort().toString() != this.get('companyVisionPlans').toArray().sort().toString());
	}.property('companyVisionPlans', 'companyVisionPlans.@each', 'prevVisionPlans'),
	packageChanged : function() {
		return (this.get('localCarrierChange') || this.get('localPlanChange'));
	}.property('localCarrierChange', 'localPlanChange'),
	skip: function() {
		App.store.get('defaultTransaction').rollback();
		this.handleTransitions();
	},
	goBackToMedical : function() {
		return this.get('transitionToMedical');
	}.property('transitionToMedical'),
	goBackToDental : function() {
		if (this.get('company.isWA')) {
			return this.get('transitionToDental') && this.get('company.dentalEnrollmentStatus');
		}

		return this.get('transitionToDental');
	}.property('company.isWA', 'company.dentalEnrollmentStatus', 'transitionToDental'),
	goBack: function() {
		App.store.get('defaultTransaction').rollback();
		if(this.get('goBackToDental')) {
			this.transitionToRoute('dentalplansreview');
		}
		else if(this.get('goBackToMedical')) {
			this.transitionToRoute('plansreview');
		}
		else {
			this.transitionToRoute('index');
		}
	},
	revertChanges : function() {
		window.location.reload();
	},
	continueEnrollment: function() {
		this.send('hideModal');
		var carrierChange = false;
		var planChange = false;
		var saveText;

		if (this.get('companyDentalPlans.length') == 0) {
			this.set('errorText', 'Please select at least one dental plan to continue.');
			return Ember.RSVP.reject();
		}

		if (this.get('companyVisionEnrollment').get('isReviewing') || this.get('prevVisionCarrier') != this.get('companyVisionCarrier.carrier')) {
			carrierChange = true;
			saveText = 'Sign Agreement';
		}
		else if(this.get('prevVisionPlans').toArray().sort().toString() != this.get('companyVisionPlans').toArray().sort().toString()) {
			planChange = true;
			saveText = 'Send E-Mails';
		}

		if (carrierChange || planChange) {
			var o = Ember.Object.create({
				carrierChange : carrierChange,
				planChange : planChange,
				lineOfCoverage: 'vision',
				saveText: saveText,
				cancelText: "Cancel Change",
				carrier: this.get('companyVisionCarrier.carrier'),
				showContinue: false,
				continueText: '',
			});
			this.send('showModal', 'review.dialog', o);
		}
		else {
			return this.handleTransitions();
		}
	},
	save: function(carrierChange, signAgreement) {
		if(carrierChange) {
			this.get('companyVisionEnrollment').set('isActive', false);
			this.get('companyVisionEnrollment').set('isEnrollmentComplete', false);
		}

		this.saveAndContinue().then(function() {
			var localPromiseArray = [];
			localPromiseArray.push(this.get('companyEnrollmentReviewApi').reload());
			localPromiseArray.push(this.get('company').reload());
			Ember.RSVP.all(localPromiseArray).then(function() {
				var action = 'carrierchange';
				var self = this;
				if(!carrierChange) {
					action = 'planchange';
				}

				$.ajax({
					url: '/custom_api/plansReview?action=' + action + '&lineOfCoverage=vision&enrollmentType=' + this.get('companyVisionEnrollment').get('enrollmentType'),
					type: "post",
					success: function(response) {
						self.send("hideModal");
						if (carrierChange && signAgreement) {
							self.transitionToRoute('reviewfinish');
						}
						else {
							self.handleTransitions();
						}
					},
					error: function(jqXHR, textStatus, errorThrown) {
					}
				});
			}.bind(this));
		}.bind(this));
	},
	createPlan: function(plan) {
		var companyVisionCarrier = this.get('companyVisionCarrier');

		if(companyVisionCarrier.get('carrier') != plan.get('stateCarrier') && this.get('companyVisionPlans.length')) {
			this.differentCarrierConfirmation(plan);
		}
		else {
			companyVisionCarrier.set('carrier', plan.get('stateCarrier'));
			this.addPlanToPackage(plan, companyVisionCarrier);
		}
	},
	addPlanToPackage: function(plan, companyVisionCarrier) {
		this._super(plan, companyVisionCarrier);
		$('html, body').animate({
			scrollTop: $("#visionHeader").offset().top - 200
		}, 500);
		this.send('hideModal');
	},
	handleTransitions : function(carrierChange) {
		if ((this.get('companyMedicalEnrollment') && this.get('companyMedicalEnrollment').get('isReviewing')) ||
				(this.get('companyDentalEnrollment') && this.get('companyDentalEnrollment').get('isReviewing'))) {
			this.transitionToRoute('reviewfinish');
		}
		else {
			this.transitionToRoute('index');
		}
	}
});


App.VisionplanselectorView = Ember.View.extend({
	didInsertElement: function() {
		$('a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('class="note orange"') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});

App.VisionplanoverviewController = _TransactionSavingController.extend({
	needs: 'visionplanselector',
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.visionplanselector');
	}.property('selectorControllerOverride'),
	createPlan: function(plan) {
		var selector = this.get('selectorController');
		selector.createPlan(plan);
	},
	cancel: function() {
		this.send('hideModal');
	}
});

App.VisionplanselectorAddconfirmController = Ember.ObjectController.extend({
	needs: 'visionplanselector',
	selectorControllerOverride: null,
	selectorController: function(key, value) {
		if (arguments.length > 1) {
			this.set('selectorControllerOverride', value);
		}

		return this.get('selectorControllerOverride') || this.get('controllers.visionplanselector');
	}.property('selectorControllerOverride'),
	addPlanToPackage: function(plan, existingCarrier) {
		var selector = this.get('selectorController');
		selector.addPlanToPackage(plan, existingCarrier);
	}
});

App.VisionplanselectorErrorController = Ember.ObjectController.extend();

var emptyArray = function() {
	return Ember.computed(function() {
		return [];
	}).property();
};

App._BenefitsInfoCollectionMixin = Ember.Mixin.create({
	// Interface
	employees: [],

	showSCorp: false,
	showCCorp: false,
	showSoleProp: false,
	showPartnership: false,

	// Implementation
	isEmployeeEmployees: Ember.computed.filterBy('employees', 'isEmployee'),

	createMissingBenefitsInfos: function() {
		/*

		WARNING(JoCo): This is gonna cause some crazy fucking shit at some point in time...

		Originally this was built with an AutoOneToOne on the back-end, and it's still there
		but I'm not trusting it to do it's work always. New models should have an EBI object,
		but old ones won't, and because there are 750k employees it'll be a PITA to back-fill
		the objects all at once (but we need to get this change in today, so there's no time).

		One day, someone is gonna touch an employee on the back-end and create the EBI object
		at the same time that an admin is setting things up in the UI, and all hell might ensure,
		but I'm not 100% sure about it.. Point of the story: screw AutoOneToOne, make your own objects.
		(It also means less load when creating EEs because there are less objects to auto-create)

		*/
		this.get('isEmployeeEmployees').forEach(function(employee) {
			var benefitsInfo = employee.get('employeeBenefitsInfo');

			if (benefitsInfo) {
				return;
			}

			employee.set('employeeBenefitsInfo', App.EmployeeBenefitsInfo.createRecord({
				employee: employee,
			}));
		});
	},

	twoPercentEmployees: Ember.computed.filterBy('isEmployeeEmployees', 'employeeBenefitsInfo.isTwoPercentOwner', true),
	addedTwoPercentEmployees: emptyArray(),
	removedTwoPercentEmployees: emptyArray(),

	llcMembers: Ember.computed.filterBy('isEmployeeEmployees', 'employeeBenefitsInfo.isLlcMember', true),
	addedLlcMembers: emptyArray(),
	removedLlcMembers: emptyArray(),

	cCorpOfficers: Ember.computed.filterBy('isEmployeeEmployees', 'employeeBenefitsInfo.isCompanyOfficer', true),
	addedCCorpOfficers: emptyArray(),
	removedCCorpOfficers: emptyArray(),

	solePropOwner: Ember.computed(function() {
		return this.get('isEmployeeEmployees').filterBy('employeeBenefitsInfo.isSolePropOwner', true).get('firstObject');
	}).property(),

	setValuesAndSave: function(objsKey, prop, val) {
		var objs = this.get(objsKey).mapBy('employeeBenefitsInfo');

		objs.invoke('set', prop, val);

		return objs.invoke('save');
	},

	saveBenefitsInfo: function() {
		var promises = [];

		this.createMissingBenefitsInfos();

		if (this.get('showSCorp')) {
			promises.pushObjects(this.setValuesAndSave('addedTwoPercentEmployees', 'isTwoPercentOwner', true));
			promises.pushObjects(this.setValuesAndSave('removedTwoPercentEmployees', 'isTwoPercentOwner', false));
		}

		if (this.get('showCCorp')) {
			promises.pushObjects(this.setValuesAndSave('addedCCorpOfficers', 'isCompanyOfficer', true));
			promises.pushObjects(this.setValuesAndSave('removedCCorpOfficers', 'isCompanyOfficer', false));
		}

		if (this.get('showSoleProp')) {
			// This is a bit different because you only expect one owner in a sole proprietorship
			var oldSolePropOwners = this.get('isEmployeeEmployees').filterBy('employeeBenefitsInfo.isSolePropOwner', true);
			var currentSolePropOwner = this.get('solePropOwner');

			oldSolePropOwners = oldSolePropOwners.filter(function(employee) {
				return employee != currentSolePropOwner;
			});

			oldSolePropOwners.invoke('set', 'employeeBenefitsInfo.isSolePropOwner', false);
			promises.pushObjects(oldSolePropOwners.mapBy('employeeBenefitsInfo').invoke('save'));

			if (currentSolePropOwner) {
				currentSolePropOwner.set('employeeBenefitsInfo.isSolePropOwner', true);
				promises.pushObject(currentSolePropOwner.get('employeeBenefitsInfo').save());
			}
		}

		if (this.get('showPartnership')) {
			promises.pushObjects(this.setValuesAndSave('addedLlcMembers', 'isLlcMember', true));
			promises.pushObjects(this.setValuesAndSave('removedLlcMembers', 'isLlcMember', false));
		}

		return Ember.RSVP.all(promises);
	},
});

_SendOnboardingReminderMixin = Ember.Mixin.create({
	sendEmailReminder: function(showEmail) {
		var newhire = this.get('model.employee.newHireWithOfferLetter');
		var promise = null;
		promise = $.ajax({ url: '/actions/send_onboarding_reminder/' + this.get('model.employee.id'), type: 'POST' });
		promise.then(function() {
			if(showEmail) {
				this.send('showModal', 'onboarding.reminder.sent', this.get('model.employee'));
			}
		}.bind(this));
	},
});

App.OnboardingActionsrequiredfromempdirRoute = App.OnboardingActionsrequiredfromtearsheetRoute = Ember.Route.extend({
	afterModel: function(model) {
		this.eventLogger.log('viewed_onboarding_action_required_modal');
	},
});

App.OnboardingActionsrequiredfromempdirController = App.OnboardingActionsrequiredfromtearsheetController = Ember.ObjectController.extend(_RedoRegistrationMixin, _SendOnboardingReminderMixin, zen._OnboardingActionsRequiredModalMixin, {
	updatingEmail: null,
	registrationEmailResentOnUpdatingEmail: false,

	showRegistrationLink: function() {
		return !this.get('employee.isRegistered') && this.get('employee.loginUrl') && this.get('employee.newHireWithOfferLetter.isHiringInfoComplete');
	}.property('employee.isRegistered', 'employee.loginUrl', 'employee.newHireWithOfferLetter.isHiringInfoComplete'),

	updateEmail: function() {
		this.set('updatingEmail', true);
	},
	save: function() {
		this.set('updatingEmail', false);
		this.eventLogger.log('email_updated_from_onboarding_actions_required_modal');
		this.get('model.employee').save().then(function() {
			if(this.get('model.employee.isActive')) {
				this.set('registrationEmailResentOnUpdatingEmail', true);
				this.send('sendEmailReminder', false);
				this.set('model.oldEmail', this.get('model.employee.email'));
			} else if(this.get('model.employee.isRegistered') == false && this.get('model.employee.newHireWithOfferLetter.isHiringSent')) {
				this.set('registrationEmailResentOnUpdatingEmail', true);
				this.send('resendNewHireRegistrationEmail', this.get('model.employee.newHireWithOfferLetter'), true);
				this.set('model.oldEmail', this.get('model.employee.email'));
			}
		}.bind(this));
	},
	close: function() {
		this.send('hideModal');
	},
	cancel: function() {
		this.set('updatingEmail', false);
		this.get('model.employee').rollback();
	},
	resendRegistrationEmail: function() {
		this.eventLogger.log('registration_link_resent_from_onboarding_actions_required_modal');
		this.send('resendNewHireRegistrationEmail', this.get('model.employee.newHireWithOfferLetter'));
	},
});

_EmployeebulkRoute = Ember.Route.extend(_MultiModelMixin, _AuthAdminMixin,{
	model: function() {
		return App.BulkUpload.find({type: 'EDO'}).then(function(bulkUploads) {
			if (bulkUploads.get('length') != 0) {
				return Ember.RSVP.resolve(bulkUploads.get('firstObject'));
			}
			else {
				var bulkObj = App.BulkUpload.createRecord({
					type: "EDO"
				});
				return Ember.RSVP.resolve(bulkObj);
			}
		});
	}
});

App.EmployeebulkController = Ember.ObjectController.extend({
	needs: ['application'],
	isOpen: function() {
		var currentPath = this.get('controllers.application.currentPath');
		return Em.A(["employeebulk.download", "employeebulk.upload"]).contains(currentPath);
	}.property('controllers.application.currentPath')
});

App.EmployeebulkDownloadRoute = Ember.Route.extend(_MultiModelMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"groupsStatus": Ember.ajaxGet('/custom_api/bulkupload/groups-status/'),
		});
	},

	setupController: function(controller, model) {
		this._super(controller, model);

		if (model.groupsStatus.showCustomGroupType) {
			controller.set('showCustomGroupType', true);
			controller.get('categories').push("customGroup");
			controller.get('categoryNames').push("Custom_Group_Type");
			controller.set('customGroupTypeName', model.groupsStatus.customGroupTypeName);
		}

		if (model.groupsStatus.showPositions) {
			controller.set('showPositions', true);
			controller.get('categories').push("positions");
			controller.get('categoryNames').push("Positions");
		}

		if (model.groupsStatus.showProjects) {
			controller.set('showProjects', true);
			controller.get('categories').push("projects");
			controller.get('categoryNames').push("Projects");
		}

		// dynamically add observers to generateUrl

		// observers for columns
		controller.get('categories').forEach(function(category) {
			this.addObserverToGenerateUrl(controller, category);
		}.bind(this));

		// observers for employee types to be included/excluded
		["includeActiveEE", "includeSetupEE", "includeTerminatedEE"].forEach(function(include) {
			this.addObserverToGenerateUrl(controller, include);
		}.bind(this));

		controller.generateUrl();
	},

	addObserverToGenerateUrl: function(controller, prop) {
		Ember.addObserver(controller, prop, controller, 'generateUrl');
	},
});

App.EmployeebulkDownloadController = _AbstractSavingController.extend({
	downloadlink: '',
	address: null,
	ssn: null,
	title: null,
	compensation: null,
	dob: null,
	bank: null,
	department: null,
	hireDate: null,
	sex: null,
	fedTax: null,
	locationAndstateTax: null,
	termDate: null,
	phone: null,
	empType: null,
	fica: null,
	tShirt: null,
	inPayroll: null,
	isLC: Ember.computed.equal('type', 'LC'),
	includeActiveEE: true,
	includeSetupEE: true,
	includeTerminatedEE: false,
	employeeTags: null,
	positions: null,
	customGroup: null,
	projects: null,
	showCustomGroupType: false,
	showPositions: false,
	showProjects: false,
	showExtraRow: Ember.computed.or('showCustomGroupType', 'showPositions', 'showProjects', 'isLC'),
	categories: [
		"address",
		"ssn",
		"title",
		"compensation",
		"dob",
		"bank",
		"department",
		"hireDate",
		"sex",
		"fedTax",
		"locationAndstateTax",
		"termDate",
		"phone",
		"empType",
		"fica",
		"tShirt",
		"inPayroll",
		"employeeTags",
	],
	categoryNames: [
		"Address",
		"SSN",
		"Job_Title",
		"Compensation",
		"Date_of_Birth",
		"Bank",
		"Department",
		"Hire_Date",
		"Gender",
		"Federal_Tax",
		"Work_Location+State_Tax",
		"Termination_Date",
		"Phone",
		"Employment_Type",
		"FICA_Exemption",
		"T-Shirt_Size",
		"inPayroll",
		"Employee_Tags",
	],
	checkAll: function(checktoggle) {
		for (var i = 0; i < this.get('categories').length; i++) {
			this.set(String(this.get('categories')[i]),  checktoggle);
		}
	},
	selectAll: function() {
		this.checkAll(true);
	},
	deselectAll: function() {
		this.checkAll(false);
	},
	generateUrl: function() {
		var request_categories = 'Email';
		var request_types = '';
		for (var i = 0; i< this.get('categories').length; i++) {
			if (this.get(this.get('categories')[i]) == true) {
				request_categories = request_categories + '+' + this.get('categoryNames')[i];
			}
		}
		if(this.get('includeActiveEE') ==  true) {
			request_types = request_types + '+' + 'Act';
		}
		if(this.get('includeSetupEE') ==  true) {
			request_types = request_types + '+' + 'Set';
		}
		if(this.get('includeTerminatedEE') ==  true) {
			request_types = request_types + '+' + 'Ter';
		}
		if(request_types != ''){
			request_types = request_types.substring(1);
		}else{
			request_types = 'Act+Set';
		}
		this.set('downloadlink', '/generate_bulkupload_spreadsheet/' + request_categories + '/' + request_types);
	}
});

App.EmployeebulkUploadRoute = _EmployeebulkRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);
		dashboard('company').then(function(company) {
			controller.set('company', company);
		});
		if (model.get('fileUrl')) {
			Ember.$.getJSON(model.get('fileUrl') + '/metadata').then(function(response) {
				controller.set('fileName', response['filename']);
			});
		}
	},
});
App.EmployeebulkUploadController = _AbstractSavingController.extend(zen._FilePickerMixin, {
	fileName: null,
	bulkUploadObj: function() {
		return this.get("model");
	}.property('model'),
	hasSpreadSheet: function() {
		return this.get('model.fileUrl') != null;
	}.property('model.fileUrl'),
	isPayrollSyncType: Ember.computed.equal('company.payrollSyncType', 'Sync'),
	isPayrollProviderGusto: Ember.computed.in('company.payrollProvider', ['ZN', 'ZD']),
	isPayrollGustoSyncActive: Ember.computed('isPayrollSyncType', 'isPayrollProviderGusto', function() {
		return this.get('switches.gusto_api_integration_flow') && this.get('isPayrollSyncType') && this.get('isPayrollProviderGusto');
	}),
	changes: function() {
		if (this.get('model.changeLogs') == null) {
			return null;
		}
		var changeLogs = this.get('model.changeLogs');
		return changeLogs.map(function(changeLogs){
			return Object.keys(changeLogs).map(function(key) {
				return {
					key: key,
					val: changeLogs[key]
				};
			});
		});
	}.property('model.changeLogs'),
	upload: function() {
		this.pickAndStore('model.fileUrl', { "extension": ['.xls','.xlsx','.XLS','.XLSX'] },
		                  function(dummy, FPFile) {
		                      this.set('fileName', FPFile[0].filename);
		                  }.bind(this));
		this.set('model.status', null);
	},
	back: function() {
		this.transitionToRoute('employeebulk.download');
	},
	validate: function() {
		this.send('showModal', 'employeebulk.validate');
		this.saveAndContinue().then(function(){
			// Have to reload page to get validate result.
			return window.location.reload();
		});
	},
	save: function() {
		this.set('status', 'Processing');
		this.send('showModal', 'employeebulk.process');
		this.saveAndContinue('employeedirectory');
	}
});

App.EmployeebulkCreateController =  Ember.ObjectController.extend();
App.EmployeebulkValidateController =  Ember.ObjectController.extend();
App.EmployeebulkProcessController =  Ember.ObjectController.extend();

App.BackgroundcheckaboutController = Ember.ObjectController.extend({
	hide: function() {
		this.send('hideModal');
	},
	transitionToSetup: function() {
		this.eventLogger.log('background-check-setup');
		this.transitionToRoute('onboardingSetup.bgc.bgcSetup');
	}
});

App.OnboardemployeetopayrollController = Ember.ObjectController.extend(zen._OnboardToPayrollMixin, {
	employee: Ember.computed.alias('model'),
	hide: function() {
		this.send('hideModal');
	},
	onboardToPayrollAction: function() {
		var employee = this.get('employee');
		var onboardToPayroll = this.get('onboardToPayroll');

		onboardToPayroll.call(this, employee).then(function() {
			this.send('hideModal');
		}.bind(this));
	},
});



App.RedoNewhireRegistrationController = _AbstractSavingController.extend({
	closeModal: function() {
		this.send('hideModal');
	}
});

App.OnboardingReminderSentController = _AbstractSavingController.extend({
	closeModal: function() {
		this.send('hideModal');
	}
});

App.EmployeesController = _AbstractSavingController.extend({
	save: function() {
		this.saveAndContinue('employeedirectory');
	}
});

App.EmployeesBasicinfoController = App.EmployeesController.extend();
App.EmployeesContactController = App.EmployeesController.extend();

App.EmployeesDocumentsController = App.EmployeesController.extend({
	deleteEmployeeCustomDocument: function(document) {
		var employee = document.get('employee');
		var transaction = App.store.transaction();
		transaction.add(document);
		document.deleteRecord();
		transaction.commit().then(function() {
			employee.reload();
			this.transitionToRoute('employee.documents', employee);
		}.bind(this));
	},
});

App.EmployeesEmergencyController = _AbstractSavingController.extend({
	save: function() { this.saveAndContinue('employees'); }
});

App.EmployeesalaryController = _ModalObjectController.extend();

var I9_LIST_A = [
	{ "id": "US_PASSPORT", "name": "U.S. Passport" },
	{ "id": "GREEN_CARD", "name": "Permanent Resident Card or Receipt (I-551)" },
	{ "id": "I551_STAMP", "name": "Foreign passport with temporary I-551 stamp" },
	{ "id": "EAD", "name": "Employment Authorization Document " },
	{ "id": "FOREIGN_PASSPORT", "name": "Foreign passport" }
];
var I9_LIST_B = [
	{ "id": "STATE_ID", "name": "Driver's license or State ID" },
	{ "id": "AGENCY_ID", "name": "Government Agency ID card" },
	{ "id": "SCHOOL_ID", "name": "School ID (with photograph)" },
	{ "id": "VOTER_ID", "name": "Voter's registration card" },
	{ "id": "MILITARY_ID", "name": "U.S. Military Card" },
	{ "id": "MILITARY_DEP_ID", "name": "Military dependent's ID Card" },
	{ "id": "CANADIAN_ID", "name": "Canadian Driver's license" }
];
var I9_LIST_C = [
	{ "id": "SSN_CARD", "name": "Social Security card" },
	{ "id": "BIRTH_CERT", "name": "U.S. Birth Certificate" },
	{ "id": "FORM_FS_545", "name": "Certification of Report of Birth (Forms DS-1350, FS-545, FS-240)" },
	{ "id": "US_CITIZEN_ID", "name": "U.S. Citizen ID Card (Form I-197)" },
	{ "id": "NATIVE_AMERICAN_TRIBAL", "name": "Native American Tribal Document" },
	{ "id": "FORM_I_179", "name": "ID Card for Use of Resident Citizen in U.S. (Form I-179)" },
	{ "id": "EMPLOYMENT_AUTHORIZATION", "name": "Employment Authorization Document Issued by DHS" }
];

App.Modifyi9showmodalController = _AbstractSavingController.extend({
	confirm: function() {
		this.transitionTo('redirect.tearsheet', this.get('employee'));
	},
	cancel: function() {
		this.send('hideModal');
	},
});

App.ItServiceDeleteTimeframeView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "After 3 Months", id: 3}),
		Ember.Object.create({name: "After 12 Months", id: 12}),
		Ember.Object.create({name: "Never", id: null}),
	],
});

App.TerminationPolicyITView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Deactivate account", id: "deac"}),
		Ember.Object.create({name: "Transfer to employee's manager", id: "trans"}),
		Ember.Object.create({name: "Decide on a per-employee basis", id: "per"}),
	],
});

var EMPLOYMENT_TYPE_CHOICES = [
	{id: "FT", name: "Full-time"},
	{id: "PT", name: "Part-time"},
	{id: "TP", name: "Intern"},
];

var CONTRACTOR_WORKER_TYPE = [
	{id: 'AW', name: 'Agency-paid Temp'},
	{id: 'CW', name: 'Company-paid Temp'},
	{id: 'IC', name: 'Independent Contractor'},
	{id: 'VE', name: 'Vendor Employee'},
	{id: 'VO', name: 'Volunteer'}
];

var CONSTRAINTS = [
	{name: "Department", id: "dept"},
	{name: "Location", id: "loc"},
	{name: "Employment Type", id: "empType"},
	{name: "Compensation Type", id: "compType"},
	{name: "Job Title", id: "title"},
	{name: "Position", id: "position"}
];

var CONSTRAINTS_CONTRACTOR_PAYMENTS = [
	{name: "Department", id: "dept"},
	{name: "Location", id: "loc"},
	{name: "Job Title", id: "title"},
	{name: "Position", id: "position"}
];

App.EmploymentTypeSelect = App.SelectBox.extend({
	sorting: false,
	content: EMPLOYMENT_TYPE_CHOICES.map(function(el){
		return Ember.Object.create(el);
	}),
	prompt: "Select One",
});

App.EmploymentTypeSelectWithWorkerTypes = App.SelectBox.extend({
	sorting: false,
	content: EMPLOYMENT_TYPE_CHOICES.concat(CONTRACTOR_WORKER_TYPE).map(function(el) {
		return Ember.Object.create(el);
	}),
	prompt: "Select One",
});

App.EmployeeStatusSelect = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id: 'Act', name: 'Active'}),
		Ember.Object.create({id: 'Ter', name: 'Terminated'}),
		Ember.Object.create({id: 'LOA', name: 'Leave of Absence'})
	],
});

App.DeleteEmployeeController = Ember.ObjectController.create({
	content: null
});

App.DeleteEmployeeView = Ember.View.extend({
	deleteEmployee: function() {
		var employee = this.get('controller.content');
		employee.set('status', 'Del');
		employee.save();
		zen.hideDialogHelper("");
	}
});

App.MedicalsettingsController = Ember.ObjectController.extend({
	company: Ember.computed.alias('model'),

	productNavElements: function() {
		var company = this.get('company');
		var listOfNavElements = [{
			label: 'Overview',
			routeName: 'benefitsOverview',
		}];

		if (this.get('settings.isAnyEnrollmentInProgress') || this.get('company.shortCircuitOESignedOff')) {
			listOfNavElements = listOfNavElements.concat({
				label: 'Open Enrollment',
				routeName: 'medicalsettings.openenrollment',
			});
		}

		listOfNavElements = listOfNavElements.concat({
			label: 'Employees',
			routeName: 'medicalsettings.employees.summary',
		});

		if (!this.get('model.isReadOnly')) {
			if (this.get('company.isApproved')) {
				listOfNavElements = listOfNavElements.concat({
					label: 'Estimate Costs',
					routeName: 'medicalsettings.estimate',
				});
			}

			if (this.get('hasDocuments')) {
				listOfNavElements.push({
					label: 'Terms and Agreements',
					routeName: 'medicalsettings.documents',
				});
			}

			listOfNavElements.push({
				label: 'Section 125',
				routeName: 'medicalsettings.section',
			});
			if (this.get('hasAnySpd')) {
				listOfNavElements.push({
					label: 'Summary Plan Description',
					routeName: 'medicalsettings.spds',
				});
			} else if (this.get('summaryPlanDescriptionInfo')) {
				listOfNavElements.push({
					label: 'Summary Plan Description',
					routeName: 'medicalsettings.spd',
				});
			}

			if (this.get('switches.qle_doc_admin_approval')) {
				listOfNavElements.push({
					label: 'Settings',
					routeName: 'medicalsettings.settings',
				});
			}
		}

		return listOfNavElements;
	}.property('hasDocuments'),

	medicalSpds: function(){
		if(this.get('medicalPromises')===undefined) { return; }

		var medicalPlans = this.get('medicalPromises')[0];
		if(medicalPlans===undefined) {
			return;
		}

		var spds = [];
		var planArray = medicalPlans.toArray();
		planArray.forEach(function(plan) {
			if(plan.get('summaryPlanDescriptionDocument')!==null) {
				spds.push(plan);
			}
		});
		if (spds.length>0) {
			return spds;
		}
	}.property('medicalPromises'),

	dentalSpds: function(){
		if (this.get('dentalPromises')===undefined) { return; }

		var dentalPlans = this.get('dentalPromises')[0];
		if(dentalPlans===undefined) {
			return;
		}

		var spds = [];
		var planArray = dentalPlans.toArray();
		planArray.forEach(function(plan) {
			if (plan.get('summaryPlanDescriptionDocument')!==null) {
				spds.push(plan);
			}
		});
		if (spds.length>0) {
			return spds;
		}
	}.property('dentalPromises'),

	visionSpds: function(){
		if(this.get('visionPromises')===undefined) { return; }

		var visionPlans = this.get('visionPromises')[0];
		if(visionPlans===undefined) {
			return;
		}

		var spds = [];
		var planArray = visionPlans.toArray();
		planArray.forEach(function(plan) {
			if (plan.get('summaryPlanDescriptionDocument')!==null) {
				spds.push(plan);
			}
		});
		if (spds.length>0) {
			return spds;
		}
	}.property('visionPromises'),

	hasMedicalSpd : Ember.computed.gt('medicalSpds.length', 0),
	hasDentalSpd : Ember.computed.gt('dentalSpds.length', 0),
	hasVisionSpd : Ember.computed.gt('visionSpds.length', 0),

	hasAnySpd: function(){
		return this.get('hasMedicalSpd')||this.get('hasDentalSpd')||this.get('hasVisionSpd');
	}.property('hasMedicalSpd','hasDentalSpd','hasVisionSpd'),

	hasDocuments : function(){
		// Show documents tab only if user has active documents
		return this.get('company.allAgreements.length') > 0 || this.get('section125.length') > 0;
	}.property('company.allAgreements.length', 'section125.length'),
});


App._MedicalsettingsOverviewController = Ember.ObjectController.extend({
	selectedPlans: [],

	viewInsuranceDetails: function(employee) {
		this.send('showModal', 'employee.breakdown', employee);
	},
	uniqueCarriers: function() {
		var carrierIdHash = {};
		var uCarriers = Ember.A();

		this.get('companyMedicalCarriers').forEach(function(companyMedicalCarrier) {
			var currentCarrierId = companyMedicalCarrier.get('carrier.id');

			if (!carrierIdHash[currentCarrierId]) {
				carrierIdHash[currentCarrierId] = true;
				uCarriers.push(companyMedicalCarrier);
			}
		});

		this.get('companyDentalCarriers').forEach(function(companyDentalCarrier) {
			var currentCarrierId = companyDentalCarrier.get('carrier.id');

			if (!carrierIdHash[currentCarrierId]) {
				carrierIdHash[currentCarrierId] = true;
				uCarriers.push(companyDentalCarrier);
			}
		});

		this.get('companyVisionCarriers').forEach(function(companyVisionCarrier) {
			var currentCarrierId = companyVisionCarrier.get('carrier.id');

			if (!carrierIdHash[currentCarrierId]) {
				carrierIdHash[currentCarrierId] = true;
				uCarriers.push(companyVisionCarrier);
			}
		});

		return uCarriers;
	}.property('companyMedicalCarriers', 'companyDentalCarriers', 'companyVisionCarriers'),

	hasNoCarriers: Ember.computed(function() {
		var hasMedical = this.get('companyMedicalCarriers.length');
		var hasDental = this.get('companyDentalCarriers.length');
		var hasVision = this.get('companyVisionCarriers.length');

		return !(hasMedical || hasDental || hasVision);
	}).property('companyMedicalCarriers.length', 'companyDentalCarriers.length', 'companyVisionCarriers.length'),

	isMedicalApproved: function() {
		return this.get('companyMedicalCarriers') && this.get('companyMedicalCarriers').filterBy('status', 'approved').length > 0;
	}.property('companyMedicalCarriers.[]'),

	isDentalApproved: function() {
		return this.get('companyDentalCarriers') && this.get('companyDentalCarriers').filterBy('status', 'approved').length > 0;
	}.property('companyDentalCarriers.[]'),

	isVisionApproved: function() {
		return this.get('companyVisionCarriers') && this.get('companyVisionCarriers').filterBy('status', 'approved').length > 0;
	}.property('companyVisionCarriers.[]'),

	// There's no way the enrollment status is going to change from 'switched' in the employer dashboard.
	// So, why not use a property without observers?
	isCompanySwitched: function() {
		var toRet = false;
		this.get('companyMedicalEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentStatus') == 'switched') {
				toRet |= true;
			}
		});
		this.get('companyDentalEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentStatus') == 'switched') {
				toRet |= true;
			}
		});
		this.get('companyVisionEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentStatus') == 'switched') {
				toRet |= true;
			}
		});
		return toRet;
	}.property('companyMedicalEnrollments.@each.enrollmentStatus',
				'companyDentalEnrollments.@each.enrollmentStatus',
				'companyVisionEnrollments.@each.enrollmentStatus'),
	//FIXME: We have to check for SW too once the SW report is available.
	isMedicalOEOn: function() {
		var toRet = false;
		this.get('companyMedicalEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentType') == 'OE') {
				toRet |= true;
			}
		});
		return toRet;
	}.property('companyMedicalEnrollments.@each.enrollmentType'),
	isDentalOEOn: function() {
		var toRet = false;
		this.get('companyDentalEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentType') == 'OE') {
				toRet |= true;
			}
		});
		return toRet;
	}.property('companyDentalEnrollments.@each.enrollmentType'),
	isVisionOEOn: function() {
		var toRet = false;
		this.get('companyVisionEnrollments').forEach(function(enrollment) {
			if (enrollment.get('enrollmentType') == 'OE') {
				toRet |= true;
			}
		});
		return toRet;
	}.property('companyVisionEnrollments.@each.enrollmentType'),
});

// App.MedicalsettingsOpenenrollmentController = App._MedicalsettingsOverviewController.extend();

/*
 * Displayed after a user signs a Cafeteria Plan (Section 125) document.
 */
App.TaskCompleteModalController = Ember.ObjectController.extend({
	hide: function() {
		this.send('hideModal');
	},
	navigateToOverview: function(){
		this.send('hideModal');
		// Force nav bar to re-render and show
		// the 'Terms and Agreements' tab now that the user has
		// signed a document
		this.send('refresh');
		this.transitionToRoute('medicalsettings.overview');
		return;
	}
});

App.MedicalsettingsSpdsController = _TransactionSavingController.extend({
	medicalSpds: function() {
		if (this.get('medicalPromises') === undefined) {
			return;
		}

		var medicalPlans = this.get('medicalPromises')[0];
		if (medicalPlans === undefined) {
			return;
		}

		var spds = [];
		var planArray = medicalPlans.toArray();
		for (var i = 0; i < planArray.length; i++) {
			if (planArray[i].get('summaryPlanDescriptionDocument') !== null) {
				spds.push(planArray[i]);
			}
		}

		if (spds.length > 0) {
			return spds;
		}
	}.property('medicalPromises'),
	dentalSpds: function() {
		if (this.get('dentalPromises') === undefined) {
			return;
		}

		var dentalPlans = this.get('dentalPromises')[0];
		if (dentalPlans === undefined) {
			return;
		}

		var spds = [];
		var planArray = dentalPlans.toArray();
		for (var i = 0; i < planArray.length; i++) {
			if (planArray[i].get('summaryPlanDescriptionDocument') !== null) {
				spds.push(planArray[i]);
			}
		}

		if (spds.length > 0) {
			return spds;
		}
	}.property('dentalPromises'),
	visionSpds: function() {
		if (this.get('visionPromises') === undefined) {
			return;
		}

		var visionPlans = this.get('visionPromises')[0];
		if (visionPlans === undefined) {
			return;
		}

		var spds = [];
		var planArray = visionPlans.toArray();
		for (var i = 0; i < planArray.length; i++) {
			if (planArray[i].get('summaryPlanDescriptionDocument') !== null) {
				spds.push(planArray[i]);
			}
		}

		if (spds.length > 0) {
			return spds;
		}
	}.property('visionPromises'),
	hasMedicalSpd: Ember.computed.gt('medicalSpds.length', 0),
	hasDentalSpd: Ember.computed.gt('dentalSpds.length', 0),
	hasVisionSpd: Ember.computed.gt('visionSpds.length', 0),
});


App.MedicalsettingsSwitchcarrierController = Ember.ObjectController.extend({
	needs: ['planselector', 'dentalplanselector', 'visionplanselector'],
	planSelector: Ember.computed.alias("controllers.planselector"),
	dentalPlanSelector: Ember.computed.alias("controllers.dentalplanselector"),
	visionPlanSelector: Ember.computed.alias("controllers.visionplanselector"),
	switchCarrier: function(lineOfCoverage, carrier) {
		var selector, route;
		if (lineOfCoverage == "medical") {
			selector = this.get('planSelector');
			route = 'planselector';
		}
		else if (lineOfCoverage == "dental") {
			selector = this.get('dentalPlanSelector');
			route = 'dentalplanselector';
		}
		else {
			selector = this.get('visionPlanSelector');
			route = 'visionplanselector';
		}

		selector.set('enrollmentType', 'SW');
		selector.set('excludeFilter', carrier);

		var promiseArray = [];
		App.CompanyHealthEnrollment.find({
			'lineOfCoverage': lineOfCoverage,
			'isEnrollmentComplete': false
		}).then(function(companyHealthEnrollments) {
			companyHealthEnrollments.forEach(function(healthEnrollment) {
				promiseArray.push(healthEnrollment.deleteRecord());
			});
			Ember.RSVP.all(promiseArray).then(function() {
				this.transitionToRoute(route);
			}.bind(this));
		}.bind(this));
	}
});


App.UcommutersettingsController = Ember.ObjectController.extend({
	productNavElements: function() {
		var listOfNavElements = [
			{
				label: 'Overview',
				routeName: 'ucommutersettings.overview',
			},
			{
				label: 'Invoices',
				routeName: 'ucommutersettings.invoices',
				condition: this.get('unicardCommuter.shouldShowInvoices'),
			},
			{
				label: 'Enrollment & Balances',
				routeName: 'ucommutersettings.eligibleemployees',
			},
			{
				label: 'Change Eligibilities',
				routeName: 'ucommutersettings.employeeeligibility',
				condition: !this.get('isReadOnly') &&
						this.get('unicardCommuter.isCustomEligibility'),
			},
			{
				label: 'Contribution Changes',
				routeName: 'ucommutersettings.contributionchanges',
				condition: !this.get('isReadOnly'),
			},
			{
				label: 'Change Contribution',
				routeName: 'ucommutersettings.change',
				condition: !this.get('isReadOnly'),
			},
			{
				label: 'Documents',
				routeName: 'ucommutersettings.documents',
			},
			{
				label: 'Cancel Commuter Plan',
				routeName: 'ucommutersettings.cancel',
				condition: !this.get('isReadOnly'),
			},
		];
		return listOfNavElements.filter(function(e) {
			return !('condition' in e) || e.condition;
		});
	}.property(),
});

App.OnboardingController = Ember.ObjectController.extend(zen._HiringAndEmployeesAppNavBarMixin, {
	needs: ['application'],
	isFullScreen: false,
	productNavElements: function() {
		var currentPath = this.get('controllers.application.currentPath');
		var belongsToEmployeeApp = Em.A(['onboarding.bulkemployeerequest', 'onboarding.calendarfeedsetup', 'onboarding.calendarfeedaddress']).contains(currentPath);
		if (!belongsToEmployeeApp) {
			return this.get('hiringAppProductNavElements');
		}
		else {
			return this.get('employeesAppProductNavElements');
		}
	}.property('employeesAppProductNavElements', 'hiringAppProductNavElements', 'controllers.application.currentPath'),
});

App.LocationsOverviewController = Ember.Controller.extend({
	filteredEmployees: function() {
		return this.get('employees').filterProperty("status", 'Act'); //Show only active employees
	}.property('employees.@each.status'),
	locationDetails: function() {
		var locDetails = this.get('locations');
		if (this.get('isAdminLocation')) {
			locDetails = locDetails.filterProperty('id', this.get('employeeLocation.id'));
		}
		locDetails = locDetails.map(function(location){
			var employeesInLocation = this.get('filteredEmployees').filterProperty("location.id", location.get('id'));
			var isSingleton = employeesInLocation.length == 1 ? true : false;
			return { "location": location, "employeesInLocation": employeesInLocation, "isSingleton": isSingleton };
		}.bind(this));
		return locDetails;
	}.property('filteredEmployees.@each.location.id', 'locations.@each.id'),
});

App.LocationsAddlocationController = Ember.Controller.extend(_AddressMixin, {
	locName: '',
	locCountry: '',
	locStreet1: '',
	locStreet2: '',
	locCity: '',
	locState: '',
	locZip: '',
	locPhone: '',
	locCoCode: '',
	country: function() {
		return this.get('locCountry');
	}.property('locCountry'),
	isValidWfCode: function() {
		return App.Utils.isValidWfCode(this.get('locCoCode'));
	}.property('locCoCode'),
	clearAll: function() {
		this.set('locName', '');
		this.set('locCountry', '');
		this.set('locStreet1', '');
		this.set('locStreet2', '');
		this.set('locCity', '');
		this.set('locState', '');
		this.set('locZip', '');
		this.set('locPhone', '');
		this.set('locCoCode', '');
	},
	isInternational: function() {
		if (this.get('locCountry') && this.get('locCountry') != 'US') {
			return true;
		} else {
			return false;
		}
	}.property('locCountry'),
	requiresWfCoCode: function() {
		if (!this.get('isInternational') && this.get('payrollProvider') == "WF" && this.get('wfPayrollCodeType') == 5) {
			return true;
		}
		else {
			return false;
		}
	}.property('isInternational', 'payrollProvider', 'wfPayrollCodeType'),
	showExperimental: false,
	actions: {
		save: function() {
			this.set('errorText', '');
			if (this.get('locName')) {
				if(this.get('locations').findProperty('name', this.get('locName'))){
					this.set('errorText','The location name you have specified is already in use. Please specify a unique location name.');
					return;
				}
			}
			if (this.get('locCountry')) {
				if (this.get('isInternational') && (!this.get('locName') || !this.get('locStreet1') || !this.get('locCity'))) {
					this.set('errorText', 'Please specify Name, Address Line 1, City, and State (if applicable).');
					return;
				} else if (this.get('isInternational') && zen.COUNTRIES_WITH_NO_STATE.indexOf(this.get('locCountry')) == -1 && !this.get('locState')) {
					this.set('errorText', 'Please specify the State.');
					return;
				} else if (!this.get('isInternational') && (!this.get('locName') || !this.get('locStreet1') || !this.get('locCity') || !this.get('locState') || !this.get('locZip'))) {
					this.set('errorText', 'Please specify Name, Address Line 1, City, State, and Zip.');
					return;
				} else if (this.get('requiresWfCoCode') && (!this.get('locCoCode') || !this.get('isValidWfCode'))) {
					this.set('errorText', 'You must enter a valid Workforce Company Code for this location in order to continue.');
					return;
				}
			} else {
				this.set('errorText', 'Please specify Name and Country.');
				return;
			}

			var newLocation = App.CompanyLocation.createRecord();
			newLocation.set('name', this.get('locName'));
			newLocation.set('country', this.get('locCountry'));

			return newLocation.save().then(function(location) {
				if (this.get('locCountry') && this.get('locCountry') != 'US') {
					location.get('intlAddress').then(function(address) {
						address.set('street1', this.get('locStreet1'));
						address.set('street2', this.get('locStreet2'));
						address.set('city', this.get('locCity'));
						address.set('state', this.get('locState'));
						address.set('postalCode', this.get('locZip'));
						address.set('country', this.get('locCountry'));
						address.set('phone', this.get('locPhone'));
						return address.save().then(function() {
							this.clearAll();
							//This company reload is important. We cannot do it in the next route as the user may not choose to hit save there
							return location.get('company').reload().then(function() {
								this.transitionToRoute('locations.addcompanytaxinfo', location.get('intlCompanyTax'));
							}.bind(this));
						}.bind(this));
					}.bind(this));
				} else {
					location.set('street1', this.get('locStreet1'));
					location.set('street2', this.get('locStreet2'));
					location.set('city', this.get('locCity'));
					location.set('state', this.get('locState'));
					location.set('zip', this.get('locZip'));
					location.set('country', 'US');
					location.set('phone', this.get('locPhone'));
					location.set('wfCoCode', this.get('locCoCode'));
					return location.save().then(function(loc) {
						this.clearAll();
						return loc.get('company').reload().then(function() {
							this.transitionToRoute('locations.overview');
						}.bind(this));
					}.bind(this));
				}
			}.bind(this));
		},
		cancel: function() {
			this.clearAll();
			this.transitionToRoute('locations.overview');
		},
	},
});

App.LocationsAddcompanytaxinfoController = _AbstractSavingController.extend(_InternationalCompanyTaxMixin, _AddressMixin, {
	hasCompletedTaxInfo: function() {
		if (this.get('hasCompanyTaxId') && (!this.get('businessLegalName') || !this.get('businessDescription') || !this.get('federalTaxID') || !this.get('tosAgreement'))) {
			return false;
		} else if (!this.get('hasCompanyTaxId') && (!this.get('businessLegalName') || !this.get('businessDescription') || !this.get('tosAgreement'))) {
			return false;
		}
		return true;
	}.property('hasCompanyTaxId', 'businessLegalName', 'businessDescription', 'federalTaxID', 'tosAgreement'),
	save: function() {
		this.set('errorText', '');
		if (!this.get('businessLegalName') || !this.get('businessDescription')) {
			this.set('errorText', 'Please provide the legal name and the business description');
			return;
		}
		if (!this.get('tosAgreement')) {
			this.set('errorText', 'Please agree to the terms of service');
			return;
		}
		this.saveAndContinue().then(function() {
			this.transitionToRoute('locations.overview');
		}.bind(this));
	},
	skipForNow: function() {
		this.send("showModal", "internationallocationpopup", this.get('model'));
	},
});

App.InternationallocationpopupController = _AbstractSavingController.extend(_InternationalCompanyTaxMixin, {
	agreement: false,
	gotIt: function() {
		this.set('errorText', '');
		if (!this.get('tosAgreement') && !this.get('agreement')) {
			this.set('errorText', 'Please agree to the terms of service');
			return;
		}
		if (this.get('agreement')) {
			this.set('tosAgreement', this.get('agreement'));
		}
		this.saveAndContinue().then(function() {
			this.transitionToRoute('locations.overview');
		}.bind(this));
	}
});

App.LocationsEditlocationController = _AbstractSavingController.extend(_AddressMixin, _InternationalCompanyTaxMixin, {
	editing: Ember.Object.create(),
	startEditing: function(section) {
		this.set("editing." + section, true);
	},
	cancelEditing: function(section) {
		this.get('model').rollbackWithChildren().then(function() {
			this.set("editing." + section, false);
		}.bind(this));
	},
	save: function() {
		this.set('errorText', '');
        if (this.get('name')) {
            var currentLocationId = this.get('id');
            var givenLocations = this.get('locations').filter(function(location){return location.get('id') !=currentLocationId;});
            if(givenLocations.findProperty('name', this.get('name'))){
               this.set('errorText','The location name you have specified is already in use. Please specify a unique location name.');
               return;
           }
        }
		if (this.get('isInternational') && (!this.get('name') || !this.get('intlAddress.street1') || !this.get('intlAddress.city'))){
			this.set('errorText', 'Please specify Name, Address Line 1, City, and State (if applicable).');
			return;
		} else if (!this.get('isInternational') && (!this.get('name') || !this.get('street1') || !this.get('city') || !this.get('state') || !this.get('zip'))) {
		    this.set('errorText', 'Please specify Name, Address Line 1, City, State, and Zip.');
		    return;
		}
        if (this.get('requiresWfCoCode') && (!this.get('wfCoCode') || !App.Utils.isValidWfCode(this.get('wfCoCode')))) {
				this.set('errorText', 'You must enter a valid Workforce Company Code for this location in order to continue.');
				return;
		}
		this.saveAndContinue().then(function() {
			this.set("editing.address", false);
			this.set("editing.tax", false);
			this.transitionToRoute('locations.overview');
		}.bind(this));
	},
	cancel: function() {
		this.get('model').rollbackWithChildren().then(function() {
			this.set("editing.address", false);
			this.set("editing.tax", false);
			this.transitionToRoute('locations.overview');
		}.bind(this));
	},
	requiresWfCoCode: function() {
		if (!this.get('isInternational') && this.get('payrollProvider') == "WF" && this.get('wfPayrollCodeType') == 5) {
			return true;
		}
		else {
			return false;
		}
	}.property('isInternational', 'payrollProvider', 'wfPayrollCodeType'),
});

zen._IPAssigmentHelperMixin = Ember.Mixin.create({
	processAgreementChoices: function(newHire, templates, requestType){
		requestType = requestType || 'ipAgreements';
		var agreements = newHire.get(requestType);
		var newAgreements = [];
		templates.forEach(function(template) {
			var agreement = agreements.findBy('base', template);
			if(template.get('isSelected')) {
				if(!agreement) {
					agreement = this.createAgreement(newHire, template);
				}
				if(!agreement.get('isSelected')){
					agreement.set('isSelected', true);
					newAgreements.push(agreement);
				}
			}
			else if(agreement) {
				agreement.set('isSelected', false);
			}
		}.bind(this));
		return newAgreements;
	},
	createAgreement: function(newHire, template){
		return App.SignedIpAssignment.createRecord({
			"base": template,
			"newHire": newHire,
			"name": template.get('name'),
			"isTemplatized": template.get('isTemplatized'),
			"askForAddress": template.get('askForAddress'),
			"askForInventions": template.get('askForInventions'),
			"askForProprietaryInformation": template.get('askForProprietaryInformation'),
			"askForFormerAgreements": template.get('askForFormerAgreements'),
			"options": template.get('options') || '{}'
		});
	},
});

App.OnboardingAddofferletterController = _AbstractSavingController.extend(_DocumentsMixin, zen._FilePickerMixin, {
	// note this is the starting position so we don't want it to be a computed property that updates
	hideLimitations: true,
	defaultOrCustom: null,

	onDefaultOrCustom: function() {
		var defaultOrCustom = this.get('defaultOrCustom');
		if (defaultOrCustom == 'default') {
			this.set('name', 'Default YC Offer Letter');
			this.set('uploadUrl', null);
			this.set('options', '{"numStocks": true, "additionalTerms": true, "offerExpiryDate": true, "alwaysSalary": true, "vestingTypes": ["4Y1V", "4Y6M"]}');
			this.set('hideLimitations', true);
			this.set('isActive', true);
			this.set('isDefault', true);
		}
		if (defaultOrCustom == 'custom') {
			if (this.get('isUsingDefault')) {
				// reset
				this.set('name', null);
				this.set('uploadUrl', null);
				this.set('template', null);
				this.set('options', null);
			}
			this.set('hideLimitations', false);
			this.set('isActive', true);
			this.pickAndStore('uploadUrl', { "extension": ['.pdf','.doc','.docx','.rtf','txt'] });
		}
	}.observes('defaultOrCustom'),
	createDefaultIP: function() {
		var ip = App.IpAssignment.createRecord({
			"name": "Default YC IP/Confidentiality Agreement",
			"onboardingSettings": this.get('onboardingSettings'),
			"isActive": true,
			"type": 'A',
			"isDefault": true,
		});
		return this.createDefaultBlob().then(function(response){
			ip.set('templateBlob_id', response.blobId);
		}).then(function(){
			return ip.save();
		});
	},
	save: function() {
		if (!this.get('name') || !(this.get('uploadUrl') || this.get('defaultOrCustom') == 'default') || !this.get('onboardingSettings.fromName') ||
					!this.get('onboardingSettings.fromTitle') || !this.get('onboardingSettings.fromSignature')) {
			this.set('errorText', "All fields are required. Please fill in the missing fields.");
			return;
		}

		var canScrapePdfPromiseObject = this.get('canScrapePdfPromiseObject');
		canScrapePdfPromiseObject.then(function() {
			if (!canScrapePdfPromiseObject.get('scrapable')) {
				this.set('errorText', "We're unable to automatically detect the text in your custom offer letter. Please make sure you have not uploaded a scanned document.");
				return;
			}

			this.set('errorText', "");
			var isNewAndDefault = this.get('isNew') && this.get('defaultOrCustom') == 'default';
			this.saveAndContinue().then(function() {
				if (isNewAndDefault) {
					// create IP assignment that goes with it
					return this.createDefaultIP();
				}
			}.bind(this)).then(function() {
				return this.get('onboardingSettings').reload();
			}.bind(this)).then(function() {
				this.transitionToRoute('onboarding.settings');
			}.bind(this));
		}.bind(this));
	}
});

App.OnboardingAddBasecustomfieldController = Ember.ObjectController.extend({
	'_rules': Ember.Object.create({count: 0, rules: Em.A()}),
	arrangedLocations: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['name'],
			content: this.get('locations'),
		});
	}.property('locations'),
	titles: function() {
		var jobTitleArray = this.get('jobTitles');
		var allTitles = [];
		jobTitleArray.forEach(function(title) {
			allTitles.push(Ember.Object.create({name: title, id: title}));
		});
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin,{
			sortProperties: ['name'],
			content: allTitles,
		});
	}.property('jobTitles'),
	positions: function() {
		var positionsArray = this.get('allPositions');
		var allPositions = [];
		positionsArray.forEach(function(position) {
			allPositions.push(Ember.Object.create({name: position, id: position}));
		});
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin,{
			sortProperties: ['name'],
			content: allPositions,
		});
	}.property('allPositions'),
	isContractorPaymentsCompany: function() {
		return this.get('company.isContractorPaymentsCompany');
	}.property('company.isContractorPaymentsCompany'),
	isSensitiveStr: function(key, value) {
		// Setter
		if(value) {
			this.set('isSensitive', value == 'Yes');
		}
		// Getter
		var isSensitive = this.get('isSensitive');
		if(isSensitive != null) {
			return isSensitive ? 'Yes' : 'No';
		}
	}.property('isSensitive'),
	fieldPertainsToEmployer: function() {
		return this.get('fieldPertainsTo') == "employer";
	}.property('fieldPertainsTo'),
	fieldPertainsToEmployee: function() {
		return this.get('fieldPertainsTo') == "employee";
	}.property('fieldPertainsTo'),
	fieldPertainsTo: function(key, value) {
		// Setter
		if(value == "employee") {
			this.set('fieldCompleterEmployee', true);
			this.set('employerDuringHiring', null);
			this.set('employeeDuringOnboarding', null);
			this.set('isFieldRequired', null);
			this.set('canEmployeeViewField', true);
			this.set('canEmployeeEditField', true);
		}
		if(value == "employer") {
			this.set('fieldCompleterEmployee', false);
			this.set('employerDuringHiring', null);
			this.set('employeeDuringOnboarding', null);
			this.set('isFieldRequired', null);
			this.set('canEmployeeViewField', null);
			this.set('canEmployeeEditField', null);
		}
		// Getter
		var fieldCompleterEmployee = this.get('fieldCompleterEmployee');
		if(fieldCompleterEmployee != null) {
			if(fieldCompleterEmployee) {
				return "employee";
			}
			return "employer";
		}
	}.property('fieldCompleterEmployee'),
	employerDuringHiringStr: function(key, value) {
		// Setter
		if(value) {
			this.set('employerDuringHiring', value == 'Yes');
			this.set('isFieldRequired', null);
			if(value == 'No') {
				this.set('isFieldRequired', false);
			}
		}
		// Getter
		var employerDuringHiring = this.get('employerDuringHiring');
		if(employerDuringHiring != null) {
			return employerDuringHiring ? 'Yes' : 'No';
		}
	}.property('employerDuringHiring'),
	employeeDuringOnboardingStr: function(key, value) {
		// Setter
		if(value) {
			this.set('employeeDuringOnboarding', value == 'Yes');
			this.set('isFieldRequired', null);
			if(value == 'No') {
				this.set('isFieldRequired', false);
			}
		}
		// Getter
		var employeeDuringOnboarding = this.get('employeeDuringOnboarding');
		if(employeeDuringOnboarding != null) {
			return employeeDuringOnboarding ? 'Yes' : 'No';
		}
	}.property('employeeDuringOnboarding'),
	canEmployeeViewFieldStr: function(key, value) {
		// Setter
		if(value) {
			var canEmployeeViewField = (value == 'Yes');
			this.set('canEmployeeViewField', canEmployeeViewField);
			if(canEmployeeViewField == false) {
				this.set('canEmployeeEditField', false);
			}
		}
		// Getter
		var canEmployeeViewField = this.get('canEmployeeViewField');
		if(canEmployeeViewField != null) {
			return canEmployeeViewField ? 'Yes' : 'No';
		}
	}.property('canEmployeeViewField'),
	canEmployeeEditFieldStr: function(key, value) {
		// Setter
		if(value) {
			this.set('canEmployeeEditField', value == 'Yes');
		}
		// Getter
		var canEmployeeEditField = this.get('canEmployeeEditField');
		if(canEmployeeEditField != null) {
			return canEmployeeEditField ? 'Yes' : 'No';
		}
	}.property('canEmployeeEditField'),
	managerAccessStr : function(key, value) {
		// Setter
		if(value) {
			this.set('canManagerViewField', value == 'Yes');
		}
		// Getter
		var canManagerViewField = this.get('canManagerViewField');
		if(canManagerViewField != null) {
			return canManagerViewField ? 'Yes' : 'No';
		}
	}.property('canManagerViewField'),
	isFieldRequiredStr: function(key, value) {
		// Setter
		if (value) {
			this.set('isFieldRequired', value == 'Yes');
		}
		// Getter
		var isFieldRequired = this.get('isFieldRequired');
		if(isFieldRequired != null) {
			return isFieldRequired ? 'Yes' : 'No';
		}
	}.property('isFieldRequired'),
	isInEditMode: function() {
		return (this.get('id') != null);
	}.property('id'),
	constrainedStr: function() {
		var constraintType = this.get('constraintType');
		if(!constraintType) {
			return "No";
		}
		else {
			return "Yes";
		}
	}.property('constraintType'),
	disableConstraintTypeSelection: function() {
		var rtn = !this.get('constrainedStr') || this.get('constrainedStr') == 'No';
		if(rtn) {
			this.set('constraintTypeSelection', null);
		}
		return rtn;
	}.property('constrainedStr'),
	allowConstraintTypeSelection: function() {
		return !this.get('disableConstraintTypeSelection');
	}.property('disableConstraintTypeSelection'),
	addRule: function() {
		var record = App.CustomFieldConstraintObject.create();//App.CustomFieldConstraint.createRecord();
		var currentCount = this.get('_rules').get('count');
		this.get('_rules').get('rules').pushObject(App.CustomFieldRuleObject.create({'record': record, 'isActive': true, 'recordNumber': currentCount}));
		this.get('_rules').set('count', 1 + currentCount);
	},
	removeRule: function(recordNumber) {
		var record = this.get('_rules').get('rules').objectAt(recordNumber);
		record.set('isActive', false);
	},
	delete: function(customFieldConstraint) {
		this.get('_promises').push(customFieldConstraint.deleteRecord());
	},
	isValid: function() {
		return true;
	},
	getConstraints: function() {
		return [];
	},
	isCustomFieldController: function() {
		return this instanceof App.OnboardingAddcustomfieldController;
	},
	prepareToSave: function() {
		var constraintType = "";
		var isConstrained = this.get('constrainedStr') == "Yes";
		if(isConstrained) {
			constraintType = this.get('constraintTypeSelection');
		}
		this.set('constraintType', constraintType);

		// Input validations
		var errorMsg = 'Please fill in all required fields.';
		var ruleValidationFailed = false;
		if(this.get('constraintType')) {
			this.get('_rules').get('rules').forEach(function(rule) {
				if(rule.get('isActive')) {
					if(!rule.get('record').get('name') || !rule.get('record').get('equality') || !rule.get('record').get('value')) {
						ruleValidationFailed = true;
					}
				}
			}.bind(this));
		}
		var ruleCount = this.get('_rules').get('count') + this.persistedRuleCount();
		var validationFailed = (this.get('isSensitive') == null);
		var customsigfieldvalidationFailed = false;
		if(this.get('fieldPertainsTo') == null) {
			validationFailed = true;
		} else {
			//Admin should not create custom signature field for employer
			customsigfieldvalidationFailed = (this.get('customFieldSection') && !this.get('customFieldSection.fieldCompleterEmployee')) || (!this.get('customFieldSection') && !this.get('fieldCompleterEmployee'));
			customsigfieldvalidationFailed = customsigfieldvalidationFailed && this.get('isSigType');
			if(customsigfieldvalidationFailed){
				errorMsg='Signature custom fields which need to be filled by the employer cannot be created.';
			}
			if(this.get('fieldPertainsToEmployer')) {
				validationFailed = this.get('employerDuringHiring') == null || this.get('canEmployeeViewField') == null || this.get('canEmployeeEditField') == null || this.get('isFieldRequired') == null;
			}
			if(this.get('fieldPertainsToEmployee')) {
				validationFailed = this.get('employeeDuringOnboarding') == null || this.get('isFieldRequired') == null;
			}
		}
		var customValidationFailed = !this.isValid();
		var nameValidationFailed = !this.get('name');
		var fieldPertainsToValidationFailed = !this.get('fieldPertainsTo');
		var overallValidationFailed = false;
		if(this.get('readOnly')) {
			overallValidationFailed = nameValidationFailed || customValidationFailed || customsigfieldvalidationFailed;
		} else {
			overallValidationFailed = nameValidationFailed || fieldPertainsToValidationFailed || customValidationFailed ||
					customsigfieldvalidationFailed || validationFailed || (isConstrained && !this.get('constraintType') && ruleCount > 0) ||
					(isConstrained && this.get('constraintType') && ruleCount == 0) || ruleValidationFailed;
		}
		if (overallValidationFailed) {
			this.set('errorText', errorMsg);
			return false;
		}
		this.set('errorText', '');
		return true;
	},
	actions: {
		save: function() {
			if(!this.prepareToSave()) {
				return Ember.RSVP.resolve();
			}
			var isInEditMode = this.get('isInEditMode');

			return this.get('model').save().then(function() {
				var promises = this.get('_promises');

				// Create custom field select choices in the database (if there are any).
				if(this.get('isSelectType')) {
					promises = promises.concat(this.get('customFieldSelectChoices').invoke('save'));
					var selectChoices = this.get('selectChoices') || '';
					var selectChoicesArr = selectChoices.split("\n");
					for(var index = 0; index < selectChoicesArr.length; index ++) {
						var choice = selectChoicesArr[index];
						choice = choice.trim();
						if (choice.length > 254){
							this.set('errorText', 'One or more choices have exceeded the maximum character limit.');
							return Ember.RSVP.resolve();
						}
						if(choice) {
							var choiceRecord = App.CustomFieldSelectChoices.createRecord();
							choiceRecord.set('name', choice);
							choiceRecord.set('customField', this.get('model'));
							promises.push(choiceRecord.save());
						}
					}
					this.set('selectChoices', '');
				}

				if(!this.get('readOnly')) {
					// Create custom field constraints in the database (if there are any).
					this.getConstraints().forEach(function(constraint) {
						if(constraint.get('isDirty')) {
							promises.push(constraint.save());
						}
					});
					if(this.get('constraintType')) {
						this.get('_rules').get('rules').forEach(function(rule) {
							if(rule.get('isActive')) {
								var record = this.createConstraintRecord();
								record.set('name', rule.get('record').get('name'));
								record.set('equality', rule.get('record').get('equality'));
								record.set('value', rule.get('record').get('value'));
								promises.push(record.save());
								// Set the 'isActive' flag of the rule to false to prevent the rule from being briefly displayed twice when the save action is taken.
								rule.set('isActive', false);
							}
						}.bind(this));
					}
				}

				return wrapArrayPromise(promises).then(function() {
					this.get('model').reload().then(function() {
						if(this.get('fieldCompleterEmployee') && this.isCustomFieldController()) {
							this.send('showModal', 'oncustomfieldcreation', this.get('model'));
						} else {
							this.transitionToRoute('onboarding.customfield');
						}
					}.bind(this));
				}.bind(this));
			}.bind(this));
		},
	},
	showSetupDepartments: function() {
		var rtn = false;
		this.get('_rules').get('rules').forEach(function(rule) {
			if(rule.get('isActive') && (rule.get('record').get('name') == 'dept')) {
				rtn = true;
			}
		});
		this.getConstraints().forEach(function(constraint) {
			if(constraint.get('name') == 'dept') {
				rtn = true;
			}
		});
		return rtn;
	}.property('_rules', '_rules.count', '_rules.rules.@each.isActive', '_rules.rules.@each.recordName', 'customFieldConstraints.length', 'customFieldConstraints.@each.name', 'customFieldSectionConstraints.length', 'customFieldSectionConstraints.@each.name'),
});

App.OnboardingAddcustomfieldController = App.OnboardingAddBasecustomfieldController.extend({
	helpUrlMediaFileCategory: "custom_field_help_media_url",
	helpUrlFileCategory: "custom_field_help_url",
	actions: {
		onFileUploadSuccess: function(fieldName, file, data) {
			this.set(fieldName, data.url);
		},
		onFileUploadError: function(file, error) {
			this.eventLogger.log('custom-field-help-url-upload-error', {
				message: "Error uploading file: " + error.toString(),
			});
		},
	},
	nonSystemFieldCustomFieldSections: function() {
		return this.get('customFieldSections').filterBy('isSystemField', false);
	}.property('customFieldSections'),
	customFieldSectionNotEmpty: function() {
		return this.get('customFieldSections.length') > 0;
	}.property('customFieldSections.length'),
	setCustomFieldSection: function(key, value) {
		//Setter
		if(value) {
			this.set('customFieldSection', value);
		}
		//Getter
		var customFieldSection = this.get('customFieldSection');
		if(customFieldSection != null) {
			return customFieldSection;
		}
	}.property('customFieldSection'),
	customFieldSelectChoicesFormatted: function() {
		var rtn = "";
		this.get('customFieldSelectChoices').forEach(function(choice) {
			rtn += choice.get('name') + "\n";
		});
		return rtn;
	}.property('customFieldSelectChoices.@each.name'),
	deleteChoice: function(choice) {
		choice.deleteRecord();
	},
	cancel: function() {
		this.set('selectChoices', '');
		this.transitionToRoute('onboarding.customfield');
	},
	isValid: function() {
		var isValid = true;
		if(this.get('isSelectType')) {
			isValid = this.get('customFieldSelectChoices').isEvery('name');
		}
		if(this.get('isSelectType') && !this.get('isInEditMode')) {
			isValid = isValid && this.get('selectChoices');
		}
		return isValid && this.get('type');
	},
	persistedRuleCount: function() {
		return this.get('customFieldConstraints.length');
	},
	createConstraintRecord: function() {
		return App.CustomFieldConstraint.createRecord({
			'customField': this.get('model'),
		});
	},
	getConstraints: function() {
		return this.get('customFieldConstraints') || [];
	},
	persistedConstraints: Ember.computed.alias('customFieldConstraints'),
	readOnly: Ember.computed.bool('customFieldSection'),
	customFieldSectionContext: function() {
		var model = this.get('customFieldSection');
		if(model) {
			return App.OnboardingAddcustomfieldsectionController.create({
				'model' : model,
				'readOnly' : true,
				'company' : this.get('company'),
				'departments' : this.get('departments'),
				'locations' : this.get('locations'),
				'arrangedLocations' : this.get('arrangedLocations'),
				'titles': this.get('titles'),
				'positions': this.get('positions'),
			});
		} else {
			return Ember.Object.create();
		}
	}.property('customFieldSection'),
});

App.OnboardingAddcustomfieldsectionController = App.OnboardingAddBasecustomfieldController.extend({
	cancel: function() {
		this.transitionToRoute('onboarding.customfield');
	},
	persistedRuleCount: function() {
		return this.get('customFieldSectionConstraints.length');
	},
	createConstraintRecord: function() {
		return App.CustomFieldSectionConstraint.createRecord({
			'customFieldSection': this.get('model'),
		});
	},
	getConstraints: function() {
		return this.get('customFieldSectionConstraints') || [];
	},
	persistedConstraints: Ember.computed.alias('customFieldSectionConstraints'),
});

_DocumentRequestControllerMixin = Ember.Mixin.create({
	noSectionCustomFields: Ember.computed.filterByProperty('customFields', 'customFieldSection', null),
	arrangedNoSectionCustomFields: Ember.computed.sortByProperty('noSectionCustomFields', 'rank'),
	arrangedCustomFieldSections: Ember.computed.sortByProperty('customFieldSections', 'rank'),
	isIpAssignmentRequest: Ember.computed.equal('selectedOption', 'isDoingAgreements'),
	isEmployeeHandbookRequest: Ember.computed.equal('selectedOption', 'isDoingEmployeeHandbook'),
	isCustomFieldRequest: Ember.computed.equal('selectedOption', 'isDoingCustomFields'),
	isEligibilityRequest: Ember.computed.equal('selectedOption', 'isDoingEligibility'),
	isDoingBackgroundCheck: Ember.computed.equal('selectedOption', 'isDoingBackgroundCheck'),
	isDoingResendRegistration: Ember.computed.equal('selectedOption', 'resendRegistration'),
	isBackgroundCheckPremium: false,
	isBackgroundCheckIncludingMotorVehicleReport: false,
	ehTemplates: Ember.computed.alias('onboardingSettings.ehTemplates'),
	ipTemplates: Ember.computed.alias('onboardingSettings.ipTemplates'),
	templatizedEHTemplates: Ember.computed.filterByProperty('onboardingSettings.ehTemplates', 'isTemplatized', true),
	selectedIPTemplates: Ember.computed.filterByProperty('ipTemplates', 'isSelected', true),
	selectedEHTemplates: Ember.computed.filterByProperty('ehTemplates', 'isSelected', true),
	setDoingPremiumBackgroundCheck: function(key, value) {
		if (this.get('bgcEmployerAgreement.premiumOnly')){
			this.set('isBackgroundCheckPremium', true);
			return true;
		}
		if (value != null) {
			this.set('isBackgroundCheckPremium', value);
		}
		return this.get('isBackgroundCheckPremium');
	}.property('bgcEmployerAgreement.premiumOnly', 'isDoingBackgroundCheck'),
	hasSetIsDoingBackgroundCheck: function() {
		this.set('isBackgroundCheckIncludingMotorVehicleReport',
			this.get('isDoingBackgroundCheck') && this.get('isMotorVehicleReportAllowed'));
	}.observes('isDoingBackgroundCheck'),
	isMotorVehicleReportAllowed: function() {
		return this.get('bgcEmployerAgreement.motorVehicleReportAllowed');
	}.property('bgcEmployerAgreement.motorVehicleReportAllowed'),
	isMotorVehicleReportChoiceDisabled: function() {
		return this.get('bgcEmployerAgreement.motorVehicleReportRequired') === this.get('bgcEmployerAgreement.motorVehicleReportAllowed');
	}.property('bgcEmployerAgreement.motorVehicleReportRequired', 'bgcEmployerAgreement.motorVehicleReportAllowed'),

	normalReportCost: function(employees){
		return employees * 25;
	},
	premiumReportCost: function(employees){
		return employees * 10;
	},
	motorVehicleReportCost: function(employees){
		return employees * 5;
	},
	enableEeo: function(){
		var onboardingSettings = this.get('onboardingSettings');
		onboardingSettings.set('isEeo', true);
		onboardingSettings.save().then(function(onboardingSettings){
			return onboardingSettings.reload();
		});
	},
	showRepeatsQuestion: function() {
		var options = this.get('options');
		var request = this.get('selectedOption');
		var selectedEmployees = this.get('selectEmployees');
		if (!request || !selectedEmployees || request == 'isDoingCustomFields') {
			return false;
		}

		return this.get('selectEmployees').find(function(employee){
			if (request == 'eeoAsked') {
				return employee.get('employeeEeo.eeoInfoComplete');
			}
			var newHires = employee.get('newHires');
			if (!newHires) {
				return false;
			}
			return newHires.find(function(newHire){
				return newHire.get('isComplete') && (
					options.find(function(option){
						return newHire.get(option);
				}));
			});
		});
	}.property('selectedOption', 'employees.@each.newHires.[]', 'selectEmployees', 'options.@each'),
	showAboutBackgroundChecks: function() {
		this.eventLogger.log('background-check-about-modal');
		this.send('showModal', 'backgroundcheckabout');
	},
	processTemplateRequests: function(employee){
		var forceRepeats = this.get('forceRepeats');
		var selectedOption = this.get('selectedOption');

		var templates = selectedOption == 'isDoingAgreements' ? this.get('selectedIPTemplates') : this.get('selectedEHTemplates');

		employee.get('newHires').forEach(function(h) {
			if (forceRepeats && h.get('isComplete')) {
				return;
			}
			var agreements = selectedOption == 'isDoingAgreements' ? h.get('ipAgreements') : h.get('ehAgreements');
			agreements = agreements.filterProperty('isSelected');
			// don't resend already signed agreements
			templates = templates.filter(function(ip){
				return !agreements.findBy('base', ip);
			});
		});
		return templates;
	},
	getorCreateNewHire: function(employee, request, options) {
		var newHires = employee.get('newHires');
		if (this.get('forceRepeats')) {
			newHires = newHires.filterProperty('isComplete', false);
		}
		if (request != 'isDoingAgreements' && request != 'eeoAsked' && request != 'isDoingEmployeeHandbook') {
			options = options.filter(function(option){
				return !newHires.findBy(option, true);
			});
			if (options.length == 0) {
				return null;
			}
		}
		else if (request == 'eeoAsked') {
			var toAsk = this.get('forceRepeats') || !employee.get('employeeEeo.eeoInfoCompleteBySelf');
			if(!toAsk){
				return null;
			}
		}

		var hire = employee.get('newHires').find(function(h) {
			return h.get('isInProcess');
		});
		if (!hire) {
			hire = App.NewHire.createRecord({
				"employee": employee,
				"isDoingOfferLetter": false,
				"isDoingOnboarding": false,
				"status": 'setting_up'
			});
		}
		options.forEach(function(option){
			hire.set(option, true);
		});
		return hire;
	},
	calculateBackgroundCheckAmount: function(backgroundCheckEmployeeCount){
		var backgroundCheckAmount = 0;
		if(this.get('isDoingBackgroundCheck') && backgroundCheckEmployeeCount > 0) {
			backgroundCheckAmount = this.normalReportCost(backgroundCheckEmployeeCount);
			if(this.get('isBackgroundCheckPremium')) {
				backgroundCheckAmount += this.premiumReportCost(backgroundCheckEmployeeCount);
			}
			if(this.get('isBackgroundCheckIncludingMotorVehicleReport')) {
				backgroundCheckAmount += this.motorVehicleReportCost(backgroundCheckEmployeeCount);
			}
		}
		return backgroundCheckAmount;
	},
	processCustomFieldRequest: function(employee){
		var customFieldValues = employee.get('customFieldValues').filterProperty('isNotFiltered');
		var customFieldValuesToRequest = [];
		customFieldValues.forEach(function(customFieldValue){
			var customFieldValueNullOrEmpty = customFieldValue.get('value') == null || customFieldValue.get('value') == "";
			if (customFieldValueNullOrEmpty && customFieldValue.get('customField.isSelected')) {
				customFieldValuesToRequest.push(customFieldValue);
			}
		});

		if (customFieldValuesToRequest.get('length') == 0) {
			return null;
		}
		return App.CustomFieldRequest.createRecord({
			"employee": employee,
			"customFieldValues": customFieldValuesToRequest
		});
	},
	options: function(){
		var selectedOption = this.get('selectedOption');
		if (selectedOption == 'isDoingEligibility' && this.get('isDoingEligibilityProofUpload')) {
			return ['isDoingEligibility', 'isDoingEligibilityProofUpload'];
		}
		if (selectedOption == 'isDoingBackgroundCheck') {
			var options = ['isDoingBackgroundCheck'];
			if (this.get('isBackgroundCheckPremium')) {
				options.push('isBackgroundCheckPremium');
			}
			if (this.get('isBackgroundCheckIncludingMotorVehicleReport')) {
				options.push('isBackgroundCheckIncludingMotorVehicleReport');
			}
			return options;
		}
		return [selectedOption];
	}.property('selectedOption', 'isDoingEligibilityProofUpload', 'isBackgroundCheckPremium', 'isBackgroundCheckIncludingMotorVehicleReport'),
	create: function() {
		var selectedOption = this.get('selectedOption');
		var selectedEmployees = this.get('selectEmployees');
		if (!selectedOption || selectedEmployees.length == 0) {
			this.set('errorText', 'Please select at least one option.');
			return Ember.RSVP.resolve();
		}
		if (selectedOption == 'resendRegistration') {
			var employees = this.get('employeesActiveAndNotRegistered');
			var employeeRequestCount = employees.get('length');
			if(employeeRequestCount == 0) {
				this.set('errorText', 'All employees have already completed this or are in the process of doing so.');
				return Ember.RSVP.resolve();
			}
			var options = {};
			options[selectedOption] = true;
			var o = Ember.Object.create({
				options: options,
				count: employeeRequestCount,
				company: this.get('company'),
				employees: employees,
			});
			if(this.get('isSingleEmployeeRequest')) {
				o.set('employeeName', this.get('fullName'));
			}
			this.send('showModal', 'employeerequestuserconfirmation', o);
			return Ember.RSVP.resolve();
		}
		// [NERD-25953] Force reload to ensure that newly added custom fields are loaded
		var modelPromise = this.get('model').reload();
		var loadObjectsPromise = this.get('loadObjectsPromise');
		return Ember.RSVP.all([modelPromise, loadObjectsPromise, selectedEmployees]).then(function() {
			var customFieldRequests = [];
			var newAgreements = [];
			var newHires = [];
			var employeeRequestCount = 0;
			var backgroundCheckEmployeeCount = 0;
			var employees = null;

			if (selectedOption == 'isDoingCustomFields') {
				customFieldRequests = selectedEmployees.map(function(employee){
					return this.processCustomFieldRequest(employee);
				}.bind(this)).filter(identity);
				employeeRequestCount = customFieldRequests.length;
			} else {
				newHires = selectedEmployees.map(function(employee){
					var templates = [];
					if (selectedOption == 'isDoingAgreements' || selectedOption == 'isDoingEmployeeHandbook') {
						templates = this.processTemplateRequests(employee);
						if (templates.length == 0) {
							return null;
						}
					}
					var newHire = this.getorCreateNewHire(employee, selectedOption, this.get('options'));
					if(newHire) {
						backgroundCheckEmployeeCount += selectedOption == 'isDoingBackgroundCheck';
						var requestType = selectedOption == 'isDoingAgreements' ? 'ipAgreements' : 'ehAgreements';
						newAgreements = newAgreements.concat(this.processAgreementChoices(newHire, templates, requestType));
						if(newHire.get('isDirty')) {
							employeeRequestCount++;
							return newHire;
						}
						else if(selectedOption == 'isDoingAgreements' || selectedOption == 'isDoingEmployeeHandbook') {
							employeeRequestCount++;
						}
					}
					return null;
				}.bind(this)).filter(identity);
			}

			if(employeeRequestCount == 0) {
				this.set('errorText', 'All employees have already completed this or are in the process of doing so.');
				return Ember.RSVP.resolve();
			}

			var backgroundCheckAmount = this.calculateBackgroundCheckAmount(backgroundCheckEmployeeCount);
			var isDoingAnyBackGroundCheck = (selectedOption == 'isDoingBackgroundCheck') && backgroundCheckEmployeeCount > 0;
			var options = {};
			options[selectedOption] = true;
			options["isBackgroundCheckPremium"] = this.get("isBackgroundCheckPremium");
			options["isBackgroundCheckIncludingMotorVehicleReport"] = this.get("isBackgroundCheckIncludingMotorVehicleReport");
			var o = Ember.Object.create({
				options: options,
				newHires: newHires,
				count: employeeRequestCount,
				agreements: newAgreements,
				customFieldRequests: customFieldRequests,
				backgroundCheckAmount: backgroundCheckAmount,
				backgroundCheckEmployeeCount: backgroundCheckEmployeeCount,
				multipleEmployees: (employeeRequestCount > 1),
				multipleBGCEmployees: (backgroundCheckEmployeeCount > 1),
				company: this.get('company'),
				isDoingAnyBackGroundCheck: isDoingAnyBackGroundCheck,
				employees: employees,
			});

			if(this.get('isSingleEmployeeRequest')) {
				o.set('employeeName', this.get('fullName'));
			}
			this.send('showModal', 'employeerequestuserconfirmation', o);
			return Ember.RSVP.resolve();
		}.bind(this));
	},
	actions: {
		removeSelectAll: function(){
			this.set('selectAll', false);
		}
	}
});

App.OnboardingBulkemployeerequestController = _AbstractSavingController.extend(zen._EmployeeRequestControllerMixin, zen._IPAssigmentHelperMixin, _DocumentRequestControllerMixin, {
	employees: function() {
		return this.get('allEmployees').filterProperty('status', 'Act').filterProperty('isEmployee');
	}.property('allEmployees.@each.status', 'allEmployees.@each.isEmployee'),
	employeesActiveAndNotRegistered: function() {
		return this.get('selectEmployees').filterProperty('isRegistered', false);
	}.property('selectEmployees.@each.isRegistered'),
	addedEmployees: Em.A(),
	removedEmployees: Em.A(),
	addedDepartments: Em.A(),
	removedDepartments: Em.A(),
	addedLocations: Em.A(),
	removedLocation: Em.A(),
	selectedEmployees: Em.A(),
	selectedDepartments: Em.A(),
	selectedLocations: Em.A(),
	selectEmployees: function() {
		var allEmployees = this.get('employees');
		if(this.get('selectAll')) {
			return allEmployees;
		}
		var departments = this.get('addedDepartments');
		var employees = this.get('addedEmployees');
		var locations = this.get('addedLocations');
		return allEmployees.filter(function(employee){
			return employees.contains(employee) || departments.contains(employee.get('department')) || locations.contains(employee.get('location'));
		});
	}.property('selectAll', 'employees', 'addedEmployees', 'addedDepartments', 'addedLocations', 'addedEmployees.[]', 'addedDepartments.[]', 'addedLocations.[]'),
	actions: {
		create: function() {
			return this.create();
		}
	},
});

App.OnboardingEmployeerequestController = _AbstractSavingController.extend(zen._EmployeeRequestControllerMixin, zen._IPAssigmentHelperMixin, _DocumentRequestControllerMixin, {
	queryParams: ['requesttype'],
	requesttype: null,
	isSingleEmployeeRequest: true,
	actions: {
		create: function() {
			return this.create();
		}
	},
});

App.EmployeerequestuserconfirmationController = Ember.ObjectController.extend({
	hideConfirmationModal: function() {
		if (!this.get('options.resendRegistration')) {
			this.get('newHires').forEach(function(hire){
				hire.rollback();
			});
			this.get('agreements').forEach(function(agreement){
				agreement.rollback();
			});
		}
		this.send('hideModal');
	},
	actions: {
		create: function() {
			this.set('isSaving', true);
			if (this.get('options.resendRegistration')) {
				this.send('sendRegistrationEmails');
			} else {
				return wrapArrayPromise(this.get('newHires').map(function(hire){
					return hire.save();
				}.bind(this))).then(function(){
					return wrapArrayPromise(this.get('agreements').map(function(agreement){
						return agreement.save();
					}.bind(this))).then(function(){
						return wrapArrayPromise(this.get('newHires').map(function(hire){
							hire.set('status', 'ready_to_send');
							return hire.save();
						}.bind(this))).then(function(){
							return wrapArrayPromise(this.get('customFieldRequests').map(function(customFieldRequest){
								return customFieldRequest.save();
							}.bind(this))).then(function(){
								this.send('hideModal');
								this.transitionTo('/');
							}.bind(this));
						}.bind(this));
					}.bind(this));
				}.bind(this));
			}
		},
		sendRegistrationEmails: function() {
			var empIds = [];
			this.get('employees').forEach(function(emp) {
				empIds.pushObject(emp.get('id'));
			});
			$.ajax({
				url: '/actions/send_bulk_registration_reminders',
				data: JSON.stringify({empIds:empIds}),
				type: 'POST'
			});
			this.send('hideModal');
			this.transitionTo('/');
		},
	},
});

App.BulkemployeerequestconfirmationController = Ember.ObjectController.extend({
	hideConfirmationModal: function() {
		this.send('hideModal');
	},
});

App.CustomfieldrequestconfirmationController = Ember.ObjectController.extend();

App.OncustomfieldcreationController = Ember.ObjectController.extend({

	actions: {
		bulkCustomFieldRequest: function() {
			var customFields = App.CustomField.find({"fieldCompleterEmployee": true});
			var customFieldSections = App.CustomFieldSection.find({"hasFieldCompleterEmployeeCustomFields": true});
			var object = Ember.Object.create({
				"customFields": customFields,
				"_employees": dashboard('employees'),
				"customFieldSections": customFieldSections,
			});
			Ember.RSVP.all([customFields, object.get('_employees')]).then(function() {
				object.get('_employees').then(function(ees) {
					object.set('_employees', ees);
				});
				this.send('showModal', 'bulkcustomfieldrequest', object);
			}.bind(this));
		},
		returnToList: function() {
			this.send('hideModal');
			this.transitionToRoute('onboarding.customfield');
		},
	}

});

App.BulkcustomfieldrequestController = Ember.ObjectController.extend(_CountdownPromiseResolverMixin, {
	hasNoSectionCustomFields: function() {
		return this.get('customFields').filterProperty('customFieldSection', null).get('length') > 0;
	}.property('customFields', 'customFields.@each'),
	arrangedNoSectionCustomFields: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['rank'],
			content: this.get('customFields').filterProperty('customFieldSection', null),
		});
	}.property('customFields', 'onboardingSettings.customFieldSections.@each'),
	save: function() {
		var somethingIsSelected = false;
		this.get('customFields').forEach(function(customField) {
			if(customField.get('_selected')) {
				somethingIsSelected = true;
			}
		});
		if(!somethingIsSelected) {
			this.set('errorText', 'Please select at least one field');
			return;
		}
		var promise = new Ember.RSVP.Promise(function(resolve) {
			var employeesLeft = this.get('_employees.length');
			this.get('_employees').forEach(function(employee) {
				// Create request for all employees
				if(employee.get('isActive')) {
					if(employee.get('type')!='RE' || ['Act', 'Set'].indexOf(employee.get('status'))==-1 ){
						return;
					}
					var request = App.CustomFieldRequest.createRecord();
					request.set('employee', employee);
					employee.get('rawCustomFieldValues').then(function(customFieldValues) {
						customFieldValues.forEach(function(customFieldValue) {
							var customFieldValueNullOrEmpty = customFieldValue.get('value') === null || customFieldValue.get('value') === '';
							if(customFieldValueNullOrEmpty && customFieldValue.get('isNotFiltered') && customFieldValue.get('customField._selected')) {
								request.get('customFieldValues').pushObject(customFieldValue);
							}
						});
						if(request.get('customFieldValues.length') > 0) {
							request.save().then(function() {
								this.resolveIfZero(-- employeesLeft, resolve);
							}.bind(this));
						} else {
							this.resolveIfZero(-- employeesLeft, resolve);
						}
					}.bind(this));
				} else {
					this.resolveIfZero(-- employeesLeft, resolve);
				}
			}.bind(this));
		}.bind(this));
		promise.then(function() {
			this.send('hideModal');
			var object = Ember.Object.create({
				"isBulkRequest": true,
			});
			this.send('showModal', 'customfieldrequestconfirmation', object);
		}.bind(this));
	},
	cancel: function() {
		this.send('hideModal');
	},
});

App.OnboardingCustomfieldController = Ember.ObjectController.extend({
	billing: Ember.inject.service(),
	promo: Ember.computed.oneWay('model.promo'),
	canCreateNewField: Ember.computed('model.customFields.[]', 'model.hasUnlimitedCustomFieldsFeature', function() {
		var customFields = this.get('model.customFields');
		var customFieldCount = 0;
		customFields.forEach(function(customField) {
			if (customField.get('id') != null && !customField.get('isSystemField')) {
				customFieldCount++;
			}
		});

		return this.get('model.hasUnlimitedCustomFieldsFeature') || customFieldCount < 3;
	}),
	hasNoSectionCustomFields: function() {
		return this.get('customFields').filterProperty('customFieldSection', null).get('length') > 0;
	}.property('customFields', 'customFields.@each'),
	arrangedNoSectionCustomFields: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['rank'],
			content: this.get('customFields').filterProperty('customFieldSection', null),
		});
	}.property('customFields', 'onboardingSettings.customFieldSections.@each'),
	arrangedCustomFieldSections: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['rank'],
			content: this.get('customFieldSections').filterBy('isSystemField', false),
		});
	}.property('customFieldSections'),
	arrangedCustomFields: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['rank'],
			content: this.get('customFields'),
		});
	}.property('customFields'),
	actions: {
		redirectToBilling: function() {
			this.get('billing').guardFeatureWithBilling('unlimitedCustomFields', 'onboarding.customfield', 'onboarding.overview');
		},
		createCustomField: function() {
			this.transitionToRoute("onboarding.addcustomfield", App.CustomField.createRecord({ onboardingSettings: this.get('onboardingSettings') }));
		},
		createCustomFieldSection: function() {
			this.transitionToRoute("onboarding.addcustomfieldsection", App.CustomFieldSection.createRecord({ onboardingSettings: this.get('onboardingSettings') }));
		},
		moveUp: function(customfield) {
			var arrangedCustomFields = [];
			if(customfield.get('type')) {
				if(customfield.get('customFieldSection')) {
					arrangedCustomFields = customfield.get('customFieldSection.arrangedCustomFields');
				}
				else {
					arrangedCustomFields = this.get('arrangedNoSectionCustomFields');
				}
			}
			else {
				arrangedCustomFields = this.get('arrangedCustomFieldSections');
			}
			var current_index = arrangedCustomFields.indexOf(customfield);
			if ( current_index > 0 ) {
				var temp = arrangedCustomFields.objectAt(current_index - 1);
				var temp_rank = temp.get('rank');
				temp.set('rank', customfield.get('rank'));
				customfield.set('rank', temp_rank);
			}
		},
		moveDown: function(customfield) {
			var arrangedCustomFields = [];
			if(customfield.get('type')) {
				if(customfield.get('customFieldSection')) {
					arrangedCustomFields = customfield.get('customFieldSection.arrangedCustomFields');
				}
				else {
					arrangedCustomFields = this.get('arrangedNoSectionCustomFields');
				}
			}
			else {
				arrangedCustomFields = this.get('arrangedCustomFieldSections');
			}
			var current_index = arrangedCustomFields.indexOf(customfield);
			if ( current_index != -1 && current_index < arrangedCustomFields.get('length') - 1) {
				var temp = arrangedCustomFields.objectAt(current_index + 1);
				var temp_rank = temp.get('rank');
				temp.set('rank', customfield.get('rank'));
				customfield.set('rank', temp_rank);
			}
		},
		next: function() {
			var arrangedCustomFields = this.get('arrangedCustomFields');
			var arrangedCustomFieldSections = this.get('arrangedCustomFieldSections');
			var errorsExist = false;
			var errorMessage = 'Sorry, error occured while saving the order, please try again later.';
			var allPromises = [];

			var customFieldsPromises = arrangedCustomFields.map(function(arrangedCustomField) { return arrangedCustomField.save(); });
			var customFieldSectionsPromises = arrangedCustomFieldSections.map(function(arrangedCustomFieldSection) { return arrangedCustomFieldSection.save(); });
			allPromises = customFieldsPromises.concat(customFieldSectionsPromises);

			return Ember.RSVP.allSettled(allPromises).then(function(results) {
				if (results) {
					results.forEach(function(result) {
						if (result.state == 'rejected') {
							errorsExist = true;
						}
					});
					if (errorsExist) {
						this.send('showModal','customfieldorderconfirmation', {
							title: 'Oops!',
							displayMessage: errorMessage
						});
						return Ember.RSVP.reject();
					}
					this.send('showModal','customfieldorderconfirmation', {
						title: 'Awesome!',
						displayMessage: 'Sucessfully saved the order.'
					});
				}
			}.bind(this), function(error) {
				this.send('showModal','customfieldorderconfirmation', {
					title: 'Oops!',
					displayMessage: errorMessage
				});
				return Ember.RSVP.reject();
			}.bind(this));
		},
	},
});

App.CustomfieldorderconfirmationController = Ember.ObjectController.extend({
	ok: function() {
		this.send('hideModal');
	},
});

App.DeletecustomfieldsectionController = Ember.ObjectController.extend({
	delete: function(button) {
		var customFieldSection = this.get('model');
		customFieldSection.deleteRecord();
		customFieldSection.save();
		this.send('hideModal');
	},
	cancelDelete: function() {
		this.send('hideModal');
	}
});

App.DeletecustomfieldController = Ember.ObjectController.extend({
	delete: function(button) {
		var customField = this.get('model');
		// removes the customFieldString from any customReport
		var customFieldString = 'customField/' + customField.get('id');
		wrapArrayPromise(App.CustomReport.find()).then(function(customReports) {
			customReports.forEach(function(r) {
				var fields = r.get('fields').split(',');
				var i = fields.indexOf(customFieldString);
				if (i != -1) {
					fields.splice(i, 1);
					r.set('fields', fields.join(','));
					r.save();
				}
			});
		});
		customField.deleteRecord();
		customField.save();
		this.send('hideModal');
	},
	cancelDelete: function() {
		this.send('hideModal');
	}
});


App.OnboardingAddipassignmentController = _AbstractSavingController.extend(_DocumentsMixin, zen._FilePickerMixin, {

	defaultOrCustom: null,
	hideLimitations: true,
	onDefaultOrCustom: function() {
		var defaultOrCustom = this.get('defaultOrCustom');
		if (defaultOrCustom == 'default') {
			this.set('name', 'Default YC IP/Confidentiality Agreement');
			this.set('uploadUrl', null);
			this.set('isActive', true);
			this.set('hideLimitations', true);
			this.set('isTemplateComplete', true);
			this.set('isDefault', true);
		}
		if (defaultOrCustom == 'custom') {
			if (this.get('isUsingDefault')) {
				// reset
				this.set('uploadUrl', null);
				this.set('template', null);
				this.set('name', 'Custom IP/Confidentiality Agreement');
				this.set('isTemplateComplete', false);
			}
			this.set('isActive', true);
			this.set('hideLimitations', false);
			// file picker
			this.pickAndStore('uploadUrl', { "extension": ['.pdf','.doc','.docx','.rtf','txt'] });
		}
	}.observes('defaultOrCustom'),
	saveBlobIfDefault: function() {
		var defaultOrCustom = this.get('defaultOrCustom');
		if (defaultOrCustom == 'custom') {
			return Ember.RSVP.resolve();
		}
		return this.createDefaultBlob().then(function(response){
			this.set('templateBlob_id', response.blobId);
		}.bind(this));
	},
	saveAttachments: function() {
		var documents = this.get('associatedDocuments').toArray();
		return wrapArrayPromise(documents.map(function(doc){
			if (doc.get('isDirty')) {
				return doc.save();
			}
			return null;
		}.bind(this)).filter(identity));
	},
	save: function() {
		if (!this.get('name') || !(this.get('uploadUrl') || this.get('defaultOrCustom') == 'default') || !this.get('onboardingSettings.fromName') || !this.get('onboardingSettings.fromTitle') || !this.get('onboardingSettings.fromSignature')) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}
		var canScrapePdfPromiseObject = this.get('canScrapePdfPromiseObject');
		canScrapePdfPromiseObject.then(function() {
			if (!canScrapePdfPromiseObject.get('scrapable')) {
				this.set('errorText', "We're unable to automatically detect the text in your custom employment agreement. Please make sure you have not uploaded a scanned document.");
				return;
			}
			this.set('errorText', "");
			return this.saveBlobIfDefault().then(function(){
				return this.saveAndContinue().then(function(){
					return this.saveAttachments();
				}.bind(this)).then(function(){
					return this.get('onboardingSettings').reload();
				}.bind(this)).then(function(){
					this.transitionToRoute('onboarding.settings');
				}.bind(this));
			}.bind(this));
		}.bind(this));
	}
});

App.OnboardingCompanysignatureController = _AbstractSavingController.extend({
	save: function() {
		if (!this.get('fromName') || !this.get('fromTitle') || !this.get('fromSignature')) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}
		this.saveAndContinue('onboarding.settings');
	}
});

App.OnboardingAddemployeehandbookController = _AbstractSavingController.extend(zen._FilePickerMixin, {
	upload: function() {
		this.pickAndStore('handbook.uploadUrl', { "extension": ['.pdf', '.doc','.docx','.rtf','.txt'] });
	},
	save: function() {
		var handbook = this.get('handbook');
		if (!handbook.get('uploadUrl')) {
			this.set('errorText', 'Please upload a file.');
			return;
		}
		if (!handbook.get('name')) {
			this.set('errorText', 'Please enter a name.');
			return;
		}
		handbook.set('isActive', true);
		handbook.set('onboardingSettings', this.get('onboardingSettings'));
		return handbook.save().then(function(){
			this.transitionToRoute('onboarding.settings');
		}.bind(this));
	}
});


App.EnrollmentSectionController = _TransactionArraySavingController.extend({
	orderBy: function(a, b) {
		return a.get('ordering') - b.get('ordering');
	},
	sortProperties: ['ordering'],

	subsections: null,
	currentSection: null,
	currentSubsection: null,
	currentSectionLength: null,
	currentSubsectionPos: null,
	isCurrentSectionLast: false,
	isCurrentSubsectionLast: false,
	buttonText: 'Begin Enrollment',
	subheadText: '',
	errorClass: false,
	refresh: 0,
	selectedPlans: [],
	updateContent: function() {
		this.set('refresh', this.get('refresh') + 1);
	},
	next: function(isComplete) {
		var section = this.get('currentSection');
		var subsection = this.get('currentSubsection');

		var currentSubsections = section.get('orderedSubsections');
		var nextIndex = (currentSubsections.indexOf(subsection)) + 1;
		var nextSubsection;

		if (nextIndex == currentSubsections.get('length')) {
			var sections = this.get('arrangedContent');
			var nextIndex = (sections.indexOf(section)) + 1;
			if (nextIndex == sections.get('length')) {
				subsection.set('isCompleted', isComplete);
				if (isComplete) {
					var sectionCompleted = true;
					for (var i = 0; i < currentSubsections.get('length'); i++) {
						sectionCompleted =
							sectionCompleted && currentSubsections.objectAt(i).get('isCompleted');
					}
					section.set('isCompleted', sectionCompleted);
				}
				else {
					section.set('isCompleted', false);
				}
				return this.saveAndContinue().then(function() {
					var allSectionsCompleted = true;
					for (i = 0; i < sections.get('length'); i++) {
						allSectionsCompleted =
							allSectionsCompleted && sections.objectAt(i).get('isCompleted');
					}
					// TODO if all items are not complete, take to enrollment summary form
					if (allSectionsCompleted) {
						this.set('subheadText', '');
						return this.transitionToRoute('enrollmentfinaldetails');
					}
					else {
						this.set('subheadText', 'Looks like some sections are still incomplete. Please complete them to finish enrollment.');
						this.set('errorClass', true);
						return this.transitionToRoute('enrollmentsummary');
					}
				}.bind(this));
			}
			subsection.set('isCompleted', isComplete);
			if (isComplete) {
				var sectionCompleted = true;
				for(var i = 0; i < currentSubsections.get('length'); i++) {
					sectionCompleted =
						sectionCompleted && currentSubsections.objectAt(i).get('isCompleted');
				}
				section.set('isCompleted', sectionCompleted);
			}
			else {
				section.set('isCompleted', false);
			}

			return this.saveAndContinue().then(function() {
				var nextSection = sections.objectAt(nextIndex);
				nextSubsection = nextSection.get('orderedSubsections').get('firstObject');

				return this.transitionToRoute(
					"enrollment."+ nextSection.get('path') + "." + nextSubsection.get('path')
				);
			}.bind(this));
		}
		else {
			subsection.set('isCompleted', isComplete);
			return this.saveAndContinue().then(function() {
				nextSubsection = currentSubsections.objectAt(nextIndex);
				return this.transitionToRoute("enrollment." + section.get('path') + "." + nextSubsection.get('path'));
			}.bind(this));
		}
	},
	prev: function() {
		var section = this.get('currentSection');
		var subsection = this.get('currentSubsection');

		var currentSubsections = section.get('orderedSubsections');
		var prevIndex = (currentSubsections.indexOf(subsection)) - 1;
		var prevSubsection;

		if (prevIndex < 0) {
			var sections = this.get('arrangedContent');
			prevIndex = (sections.indexOf(section)) - 1;
			if (prevIndex < 0) {
				this.transitionToRoute('enrollmentsummary');
				return;
			}
			var prevSection = sections.objectAt(prevIndex);
			var length = prevSection.get('orderedSubsections').get('length');
			prevSubsection = prevSection.get('orderedSubsections').objectAt(length - 1);

			this.transitionToRoute(
				"enrollment."+ prevSection.get('path') + "." + prevSubsection.get('path')
			);

			return;
		}
		else {
			prevSubsection = currentSubsections.objectAt(prevIndex);
		}
		this.transitionToRoute(
			"enrollment."+ section.get('path') + "." + prevSubsection.get('path')
		);
	},
	init: function() {
		this._super.apply(this, arguments);
		var that = this;
		this.addArrayObserver({
			arrayWillChange: function(arr, start, removeCount, addCount) {
			},
			arrayDidChange: function(arr, start, removeCount, addCount) {
				var sections = arr.get('arrangedContent');
				if (!sections) {
					return;
				}
				var allSectionsCompleted = true;
				for (var i = 0; i < sections.get('length'); i++) {
					allSectionsCompleted =
						allSectionsCompleted && sections.objectAt(i).get('isCompleted');
				}
				App.Company.find('me').then(function(company) {
					if (allSectionsCompleted) {
						that.set('buttonText', 'Finish Enrollment');
					}
					else if (company.get('hasEnrollmentBegun')) {
						that.set('buttonText', 'Resume');
					}
					else {
						that.set('buttonText', 'Begin Enrollment');
					}
				});
			}
		});
	},
});

/*
 * -- ENROLLMENT --
 */

App.EnrollmentController = Ember.ObjectController.extend({
	needs: ['enrollmentSection'],
	refresh: 0,
	optionalAttributes: [
		'physicalAddressStreet2',
		'physicalAddressCounty',
		'billingAddressStreet2',
		'dbaName',
		'locations',
		'visionContributionDependents',
		'dentalContributionDependents',
		'contributionDependents',
		'affiliationName',
		'affiliationNumberOfEmployees',
		'startDate',
		'endDate',
		'coverageHMOPPOType', 'isThisTotalReplacement', 'terminatedNonPayment',
		'groupID',
		'carrier',
		'isClientPeo',
		'coverageBeginDate', 'coverageTerminatedDate',
		'haveCurrentDentalCoverage',
		'currentDentalCarrier', 'currentDentalStartDate', 'currentDentalEndDate',
		'currentVisionCarrier', 'currentVisionStartDate', 'currentVisionEndDate',
		'haveCurrentVisionCoverage',
		'workersCompCarrier', 'workersCompPolicyNumber', 'workersCompRenewalDate', 'allOwners',
		'anyOwnerNotCovered', 'namesNotCovered',
		'employeesEnrolledCount',
		'willExerciseCobra', 'cobraIsDisabled',
		'cobraDisablingCondition',
		'cobraName', 'cobraDob', 'cobraSsn', 'cobraType',
		'cobraEventDescription', 'cobraDate',
		'unableToWorkInjuriesNames',
		'priorCarrierExtension',
		'unableToWorkInjuriesDates',
		'expensiveExpensesAnyLastTwoYearsNames',
		'anyPregnanciesOrHospitalizationNames',
		'anyEverSeriousTreatmentNames',
		'anyAIDsNames',
		'howManyPregnant', 'dueDates',
		'namesNotWC', 'titlesNotWC',
		'yesNoW2Explanation',
		'effectiveDate',
		'contributionType',
		'contributionEmployee',
		'dentalContributionType',
		'dentalContributionEmployee',
		'visionContributionType',
		'visionContributionEmployee',
		'entityType',
		'entityChangedRecently',
		'entityChangedOnPayrollQWTR',
		'areAllOwnersOnQWTR',
		'anyEEotherThanOwnerCouple',
		'status',
		'isQuarterlyWageTaxAvailable'
	],

	isAttributePresent: function(attribute) {
		var currentSubsection = this.get('controllers.enrollmentSection').get('currentSubsection');
		var attrs = currentSubsection.get('attrList');
		return attrs.contains(attribute);
	},
	navTo: function(section, subsection) {
		var router = this.target.get("router");
		if (!subsection) {
			subsection = section.get('subsections').objectAt(0);
		}
		var path = 'enrollment.' + section.get('path') + "." + subsection.get('path');
		router.transitionTo(path);
	},

	actions: {
		save: function(isComplete) {
			var model = this.get('content');
			var sectionController = this.get('controllers.enrollmentSection');
			var subsection = sectionController.get('currentSubsection');
			var attrList = subsection.get('attrList');
			isComplete = (isComplete != null) ? isComplete : true;
			var optionals = this.get('optionalAttributes');
			if (isComplete) {
				for (var i = 0; i < attrList.get('length'); i++) {
					var attr = attrList.objectAt(i);
					if (optionals.indexOf(attr) != -1) {
						continue;
					}
					var value = model.get(attr);
					if (typeof(value) == 'object' && !value) {
						isComplete = false;
						break;
					}
					if (typeof(value) == 'string') {
						value = value ? $.trim(value) : value;
						if (!value || value == '') {
							if (value == "0") {
								continue;
							}
							isComplete = false;
							break;
						}
					}
					if (typeof(value) == 'boolean') {
						if (!value) {
							model.set(attrList.objectAt(i), false);
						}
					}
				}
			}

			var isDirty = model.get('isDirty');
			if (isDirty) {
				// save to server
				App.store.commit();
			}
			return sectionController.next(isComplete);
		},
	},

	prev: function() {
		this.get('controllers.enrollmentSection').prev();
	}
});

App.EnrollmentView = Ember.View.extend({
	didViewChange: function() {
		this.rerender();
	}.observes('controllers.enrollmentSection.refresh')
});


App.PercentCoverageSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Percentage", id: "P"}),
	],
});

App.VDCoverageSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Percentage", id: "P"}),
		Ember.Object.create({name: "Fixed", id: "F"})
	],
});

App.EntityTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Corporation", id: "CO"}),
		Ember.Object.create({name: "LLC", id: "LC"}),
		Ember.Object.create({name: "Partnership", id: "PA"}),
		Ember.Object.create({name: "S-Corporation", id: "SC"}),
		Ember.Object.create({name: "Sole Proprietorship", id: "SP"}),
		Ember.Object.create({name: "LLP", id: "LP"}),
		Ember.Object.create({name: "Non-Profit Corporation", id: "NP"})
	],
});

App.LLCTaxTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Partnership or Sole Proprietorship", id: "PA/SP"}),
		Ember.Object.create({name: "Corporation", id: "CO"}),
		Ember.Object.create({name: "S-Corporation", id: "SC"}),
	],
});

App.HourlyOrSalarySelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:null, name:"Select"}),
		Ember.Object.create({name: "Hourly", id: "H"}),
		Ember.Object.create({name: "Salary", id: "S"}),
	],
});

App.DefaultingHourlyOrSalarySelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Salaried", id: "S"}),
		Ember.Object.create({name: "Hourly", id: "H"}),
	],
});
App.AdministratedDepartmentSelectView = App.SelectBox.extend({
	sorting: false,
	classNames: ['calendar-feed-department-selectbox'],
	attributeBindings: ['disabled'],
	disabled: function(){
		return this.get("controller.isAdminDepartment");
	}.property("controller.isAdminDepartment"),
	prompt: "All"
});
App.AdministratedLocationSelectView = App.SelectBox.extend({
	sorting: false,
	classNames: ['calendar-feed-location-selectbox'],
	attributeBindings: ['disabled'],
	disabled: function(){
		return this.get("controller.isAdminLocation");
	}.property("controller.isAdminLocation"),
	prompt: "All"
});
App.CustomFieldTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Single-line text", id: "S"}),
		Ember.Object.create({name: "Multi-line text", id: "M"}),
		Ember.Object.create({name: "Date", id: "D"}),
		Ember.Object.create({name: "Number", id: "N"}),
		Ember.Object.create({name: "File", id: "F"}),
		Ember.Object.create({name: "Multiple Choice", id: "SS"}),
		Ember.Object.create({name: "Yes or No", id: "B"}),
		Ember.Object.create({name: "Signature", id: "SI"}),
	],
	prompt:"Select"
});
App.CustomFieldConstraintTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "any", id: "or"}),
		Ember.Object.create({name: "all", id: "and"}),
	],
});
App.CustomFieldConstraintDimensionSelectView = App.SelectBox.extend({
	sorting: false,

	content: Ember.computed(function() {
		return (
			this.get('isContractorPaymentsCompany') ? CONSTRAINTS_CONTRACTOR_PAYMENTS : CONSTRAINTS
		).map(function(constraint){
			return Ember.Object.create(constraint);
		});
	}).property('isContractorPaymentsCompany'),
});
App.CustomFieldConstraintEqualitySelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "is", id: 'eq'}),
		Ember.Object.create({name: "is not", id: 'ne'}),
	],
	prompt:"Select"
});

App.OnlyCliffStockOptionsSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "No stock options", id: ""}),
		Ember.Object.create({name: "Stock options with 4-year vesting and 1-year cliff", id: "4Y1V"}),
	],
});

App.StockOptionsSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "No stock options", id: ""}),
		Ember.Object.create({name: "Stock options with 4-year vesting and 1-year cliff", id: "4Y1V"}),
		Ember.Object.create({name: "Stock options with 4-year vesting and 6-month cliff", id: "4Y6M"}),
		Ember.Object.create({name: "Stock options vested immediately", id: "0Y0V"}),
	],
});

App.StockTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "ISO", id: "ISO"}),
		Ember.Object.create({name: "NSO", id: "NSO"}),
		Ember.Object.create({name: "RSO", id: "RSO"}),
	],
});

App.DentalSameAsHealth = Ember.Checkbox.extend({
	checkedDidChange: function() {
		if (this.get('checked')) {
			var underwriting = this.get('controller').get('model');
			underwriting.set('currentDentalCarrier', underwriting.get('carrier'));
			underwriting.set('currentDentalStartDate', underwriting.get('startDate'));
			underwriting.set('currentDentalEndDate', underwriting.get('endDate'));
		}
	}.observes('checked')
});

App.VisionSameAsHealth = Ember.Checkbox.extend({
	checkedDidChange: function() {
		if (this.get('checked')) {
			var underwriting = this.get('controller').get('model');
			underwriting.set('currentVisionCarrier', underwriting.get('carrier'));
			underwriting.set('currentVisionStartDate', underwriting.get('startDate'));
			underwriting.set('currentVisionEndDate', underwriting.get('endDate'));
		}
	}.observes('checked')
});

App.BusinessDescription = Em.TextArea.extend({
	classNames: ['bizDesc']
});


Ember.computed.nSections = function(n) {
	return Ember.computed(function() {
		var numSections = n;
		return Array.apply(null, new Array(numSections)).map(function() {
			return Ember.Object.create({
				isPresent: false,
			});
		});
	});
};

App.NumberedSection = Ember.View.extend({
	layoutName: 'enrollment/numbered-section',
	classNames: ['sizeless'],

	sections: null,
	baseIndex: null,
	isVisible: null,

	visibleIndex: Ember.computed(function() {
		if (!this.get('isVisible')) {
			return;
		}

		var sections = this.get('sections');
		var index = this.get('baseIndex') - 1;

		sections.objectAt(index).set('isPresent', true);

		var realIndex = 0;

		for (var i = 0; i < index; i += 1) {
			if (sections.objectAt(i).get('isPresent')) {
				realIndex += 1;
			}
		}

		return realIndex + 1;
	}).property('sections.@each.isPresent', 'isVisible').cacheable(false),

	willDestroyElement: function() {
		var sections = this.get('sections');
		var index = this.get('baseIndex') - 1;

		sections.objectAt(index).set('isPresent', false);
	},
});

Ember.computed.sectionRequired = function(sectionPath) {
	return Ember.computed(function() {
		var sections = this.get('controllers.enrollmentSection').get('content');

		return sections.find(function(section) {
			return !Ember.isEmpty(section.get('subsections')) && section.get('subsections').findProperty('path', sectionPath) !== undefined;
		}) !== undefined;
	});
};

function findSubsection(sections, subsectionPath) {
	sections = sections.toArray();

	for (var i = 0; i < sections.length; i += 1) {
		var curSection = sections[i].get('subsections').toArray();

		for (var j = 0; j < curSection.length; j += 1) {
			if (curSection[j].get('path') == subsectionPath) {
				return curSection[j];
			}
		}
	}

	return null;
}

Ember.computed.sectionIncomplete = function(subsectionPath) {
	return Ember.computed(function() {
		var sections = this.get('controllers.enrollmentSection').get('content');
		var subsection = findSubsection(sections, subsectionPath);

		return subsection && !subsection.get('isCompleted');
	}).cacheable(false);
};

Ember.computed.sectionComplete = function(subsectionPath) {
	return Ember.computed(function() {
		var sections = this.get('controllers.enrollmentSection').get('content');
		var subsection = findSubsection(sections, subsectionPath);

		return subsection && subsection.get('isCompleted');
	}).cacheable(false);
};

Ember.computed.anyEnrollment = function(propertyKey) {
	return Ember.computed(function() {
		var enrollments = this.get('enrollments');

		return enrollments.find(function(enrollment) {
			return enrollment.get(propertyKey);
		}) !== undefined;
	}).property('enrollments.@each.' + propertyKey).cacheable(false);
};

Ember.computed.enrollmentsAgreed = function(propertyKey) {
	return Ember.computed(function() {
		return this.get('enrollments').every(function(enrollment) {
			return enrollment.get(propertyKey);
		});
	}).property('enrollments.@each.' + propertyKey);
};


Ember.computed.number = function(key) {
	return Ember.computed(function() {
		var val = this.get(key);

		return Number(val);
	}).property(key);
};

App.WAITING_PERIODS_MAP = {
	'': null,
	'Default': 'First of the month following hire date',
	'Hire Date': 'Hire Date',
	'One Month': 'First of the month after 1 month',
	'1 Day': 'First of the month after 1 day',
	'7 Days': 'First of the month after 7 days',
	'15 Days': 'First of the month after 15 days',
	'29 Days': 'First of the month after 29 day(s)',
	'30 Days': 'First of the month after 30 day(s)',
	'31 Days': 'First of the month after 31 day(s)',
	'45 Days': 'First of the month after 45 days',
	'59 Days': 'First of the month after 59 days',
	'60 Days': 'First of the month after 60 day(s)',
	'61 Days': 'First of the month after 61 day(s)',
	'90 Days': 'First of the month after 90 day(s)',
	'180 Days': 'First of the month after 180 day(s)',
	'Two Months': 'First of the month after 2 months',
	'Three Months': 'First of the month after 3 months',
	'Six Months': 'First of the month after 6 months',
	'Nine Months': 'First of the month after 270 days',
	'One Year': 'First of the month after 1 year',
	'Eighteen Months': 'First of the month after 18 months',
	'Two Years': 'First of the month after 2 years',
	'Same Month': 'First of the month prior Hire Date',
	'Hire + 4 Weeks': '4 Weeks from Hire Date',
	'Hire + 1 Month': '1 month from Hire Date',
	'Hire + 2 Months': '2 months from Hire Date',
	'Hire + 3 Months': '3 months from Hire Date',
	'Hire + 4 Months': '4 months from Hire Date',
	'Hire + 6 Months': '6 months from Hire Date',
	'Hire + 1': '1 day from Hire Date',
	'Hire + 10': '10 days from Hire Date',
	'Hire + 14': '14 days from Hire Date',
	'Hire + 15': '15 days from Hire Date',
	'Hire + 20': '20 days from Hire Date',
	'Hire + 25': '25 days from Hire Date',
	'Hire + 29': '29 days from Hire Date',
	'Hire + 30': '30 days from Hire Date',
	'Hire + 31': '31 days from Hire Date',
	'Hire + 45': '45 days from Hire Date',
	'Hire + 60': '60 days from Hire Date',
	'Hire + 65': '65 days from Hire Date',
	'Hire + 89': '89 days from Hire Date',
	'Hire + 90': '90 days from Hire Date',
	'Hire + 91': '91 days from Hire Date',
	'Hire + 180': '180 days from Hire Date',
	'Hire + 1 Year': '1 year from Hire Date',
	'Hire + 1 Year + 1 Day': '1 year + 1 day from Hire Date',
	'Hire + 2 Years': '2 years from Hire Date',
	'Hire + 3 Years': '3 years from Hire Date',
	'Hire + 5 Years': '5 years from Hire Date',
	'7 After 30 days': '7th of the month after 30 days',
	'15 Default': '15th of the month following hire date',
	'15 After 30 days': '15th of the month after 30 days',
	'15 After 60 days': '15th of the month after 60 days',
	'15 After 90 days': '15th of the month after 90 days',
	'15 After 1 month': '15th of the month after 1 month',
	'15 After 2 months': '15th of the month after 2 months',
	'15 After 3 months': '15th of the month after 3 months',
};

// This list represents the supported values that can be set by the user.
// A waiting period that comes from the back end, should be either in this list, or in
// the list below (uiUnsupportedPeriods)
// The unsupported list is defined on Confluence 'Waiting Periods Displayed in Admin Flows' page https://confluence.inside-zen.com/x/B64SAw
App.UiSupportedPeriods = [
	'',
	'Default',
	'Hire Date',
	'One Month',
	'15 Days',
	'30 Days',
	'45 Days',
	'60 Days',
	'90 Days',
	'91 Days',
	'Two Months',
	'Three Months',
	'Six Months',
	'Nine Months',
	'One Year',
	'Eighteen Months',
	'Two Years',
	'Same Month',
	'Hire + 4 Months',
	'Hire + 6 Months',
	'Hire + 29',
	'Hire + 30',
	'Hire + 60',
	'Hire + 65',
	'Hire + 89',
	'Hire + 90',
	'7 After 30 days',
	'15 Default',
	'15 After 30 days',
	'15 After 60 days',
	'15 After 90 days',
	'15 After 1 month',
	'15 After 2 months',
	'15 After 3 months',
];

// This list is not actually used, but helps us see the waiting periods that we filter out.
// The list is defined on Confluence 'Waiting Periods Displayed in Admin Flows' page https://confluence.inside-zen.com/x/B64SAw
App.UiUnsupportedPeriods = [
	'1 Day',
	'7 Days',
	'59 Days',
	'61 Days',
	'180 Days',
	'Hire + 1',
	'Hire + 10',
	'Hire + 14',
	'Hire + 15',
	'Hire + 20',
	'Hire + 25',
	'Hire + 31',
	'Hire + 45',
	'Hire + 180',
	'Hire + 1 Year',
	'Hire + 1 Year + 1 Day',
	'Hire + 2 Years',
	'Hire + 3 Years',
	'Hire + 5 Years',
	'Hire + 1 Month',
	'Hire + 4 Weeks',
	'Hire + 2 Months',
	'Hire + 3 Months',
	'Hire + 6 Months',
];

App.SIMILAR_WAITING_PERIODS = [
	["30 Days", "One Month"],
	["60 Days", "Two Months"],
	["90 Days", "Three Months"],
	["Hire + 30", "Hire + 1 Month"],
	["Hire + 60", "Hire + 2 Months"],
	["Hire + 90", "Hire + 3 Months"],
];


App.GamificationCard = Ember.View.extend({
	templateName: 'GamificationCard',
	headingHTML: "",
	mainText: "",
	iconCssClass: "",
	footer: "",

	isComplete: function() {
		return this.get('percentageComplete') == 100;
	}.property('percentageComplete'),
	wasPreviouslyComplete: function () {
		// TODO: TBD
		return false;
	}.property(''),
	didInsertElement: function() {
		this._super();
		var newStrokeOffset = 173;
		var wasPreviouslyComplete = this.get('wasPreviouslyComplete');
		var isComplete = this.get('isComplete');

		if (!isComplete) {
			newStrokeOffset = 173 - (173 * parseFloat(this.get('percentageComplete'))/100);
		} else {
			newStrokeOffset = 0;
		}

		setTimeout(function () {
			var svgRoot = this.$().find('.progress-circle'),
				svgCirclePath = svgRoot.find('path'),
				svgTick = svgRoot.find('polygon');
			svgCirclePath.css('stroke-dashoffset', newStrokeOffset + 'px');
			if (wasPreviouslyComplete) {
				// Switch off the transition animations
				svgCirclePath.css('transition', 'initial');
				svgTick.css('transition', 'initial');
			}

			if (isComplete) {
				svgCirclePath.css('fill', 'rgb(115, 185, 50)').css('fill-opacity', '1');
				svgTick.css('fill', 'white').css('fill-opacity', '1').css('stroke', 'white');
			}
		}.bind(this), wasPreviouslyComplete ? 0 : 600);
	},
});

// Just a wrapper over Gamification card.
// This should have the model borCompanyNav
App.BoRGamificationCard = Ember.View.extend(_BoRGamificationUtils, {
	templateName: 'BoRGamificationCard',
	borCardName: "",
	isInvoiceMandatory: function() {
		var state = this.get('company.state');
		if (state === 'WA' || state === 'NC') {
			return true;
		}
		return false;
	}.property('company.state'),
	headingHTML: function() {
		var headingText;
		if ((this.get('borCardName') === 'upload-documents') && this.get('isInvoiceMandatory')) {
			headingText = this.get('company.state') + ' State Requirement';
		} else {
			headingText = this.get('borCardNameToData')[this.get('borCardName')].heading;
		}
		var firstRoute = this.firstRouteNameInCard(this.get('borCardName'), this.get('borCompanyNav.borCardsToRoutesObj'));
		var hyperlink = "#/" + this.getEmberRouteForRouteName(firstRoute, this.get('borCompanyNav.routeNameToEmberRouteObj')).replace('\.', '\/');
		var dynamicRouteParam = this.dynamicRouteParams(firstRoute, this.get('borCompanyNav.routeNameToDynamicParamsObj'));
		if (dynamicRouteParam) {
			hyperlink += "/" + dynamicRouteParam;
		}
		return '<a href="' + hyperlink + '">' + headingText + ' &#8594;</a>';
	}.property('borCardName', 'borCardNameToData', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.routeNameToEmberRouteObj', 'borCompanyNav.routeNameToDynamicParamsObj'),
	mainText: function() {
		if (this.get('borCardName') === 'upload-documents') {
			if (this.get('isInvoiceMandatory')) {
				return 'We need your most recent invoices.';
			}
			if (this.get('shortCircuitOEStats.shortCircuitOEEligible')) {
				return 'We need your most recent renewal packet.';
			}
		}
		return this.get('borCardNameToData')[this.get('borCardName')].mainText;
	}.property('borCardName'),
	footer: function() {
		if ((this.get('borCardName') === 'upload-documents') && this.get('isInvoiceMandatory')) {
			return '<i><strong><font color="darkred">Important</font></strong>: Complete <strong>immediately</strong> to <strong>continue</strong> your switch to Zenefits.</i>';
		}
		if (this.get('shortCircuitOEStats.shortCircuitOEEligible') && this.get('borCardName') != 'carrier-data') {
			return '<strong>Deadline:</strong> ' + this.get('deadlineStrIfApplicable');
		}
		return this.get('borCardNameToData')[this.get('borCardName')].footer;
	}.property('borCardName', 'shortCircuitOEStats.leastSCOERenewalDate'),
	// If we can't find the least renewal date among all the carriers, this will return today as deadline.
	deadlineStrIfApplicable: function() {
		var momentDeadline;
		if (!this.get('shortCircuitOEStats.leastSCOERenewalDate')) {
			momentDeadline = moment();
		}
		else {
			momentDeadline = moment(this.get('shortCircuitOEStats.leastSCOERenewalDate')).subtract(16, 'days');
		}
		var today = moment();
		if (momentDeadline.isBefore(today)) {
			momentDeadline = today;
		}
		return momentDeadline.format('MMM D, YYYY');
	}.property('shortCircuitOEStats.leastSCOERenewalDate'),
	iconCssClass: function() { return this.get('borCardNameToData')[this.get('borCardName')].iconCssClass; }.property('borCardName'),
	percentageComplete: function() {
		var thisCardToRoutes = this.get('borCompanyNav.borCardsToRoutesObj')[this.get('borCardName')];
		var routesInThisCard = Object.keys(thisCardToRoutes);
		var maxStepsInThisCard = 0,
			stepsCompletedInThisCard = 0;
		for(var i=0; i<routesInThisCard.length; i++) {
			maxStepsInThisCard += this.maxStepsInTheRoute(routesInThisCard[i], this.get('borCompanyNav.borCardsToRoutesObj'));
			stepsCompletedInThisCard += this.numStepsCompletedForTheRoute(routesInThisCard[i], this.get('borCompanyNav.borRoutesStatusesObj'));
		}
		return Math.round((stepsCompletedInThisCard / maxStepsInThisCard) * 100);
	}.property('borCompanyNav.borRoutesStatusesObj', 'borCardName', 'borCompanyNav.borCardsToRoutesObj'),
	isValid: function() {
		return this.isValidCard(this.get('borCardName'), this.get('borCompanyNav.borCardsToRoutesObj'));
	}.property('borCardName', 'borCompanyNav.borCardsToRoutesObj'),

	borCardNameToData: {
		"employees": {
			"heading": "Meeting your employees",
			"mainText": "We'd love to meet your employees. We can also update your records when we do!",
			"footer": '',
			"iconCssClass": "census",
		},
		"carrier-data": {
			"heading": "Fast-track information sharing",
			"mainText": "Permit online access to carrier information.",
			"footer": '<i><strong>Important:</strong> Expedite your switch by <strong>2 - 3 weeks.</strong> </i>',
			"iconCssClass": "online-access",
		},
		"current-plans": {
			"heading": "Tell us about the plans you have",
			"mainText": "We need names of your current plans.",
			"footer": '<i><strong>Important:</strong> Complete before you hire a new employee.</i>',
			"iconCssClass": "current-plans",
		},
		"new-plans-and-review": {
			"heading": "Tell us about the plans you want",
			"mainText": "We need to know if you are renewing, changing, or adding new plans.",
			"footer": 'Please complete as soon as possible.', // This will not be taken, footer property overrides this.
			"iconCssClass": "new-plans-and-review",
		},
		"census": {
			"heading": "Tell us about your employees",
			"mainText": "We need your employees' names, email addresses, and demographic information.",
			"footer": '<i><strong>Important:</strong> Complete immediately to avoid delays.</i>',
			"iconCssClass": "census",
		},
		"cobra": {
			"heading": "Tell us about your recently terminated employees and COBRA enrollees",
			"mainText": "We need to know your recently terminated employees and previous COBRA administrator to transfer COBRA administration.",
			"footer": "Please complete as soon as possible.",
			"iconCssClass": "census",
		},
		"upload-documents": {
			"heading": "Upload plan documents",
			"mainText": "We need your most recent invoices.",
			"footer": "<i>Expedite your switch by up to 1 week.</i>",
			"iconCssClass": "upload-documents",
		},
		"contributions": {
			"heading": "Tell us about your contribution scheme",
			"mainText": "We need to know how much you plan to contribute towards your employees' health insurance premiums.",
			"footer": "<i>Expedite your switch by 1-3 days.</i>",
			"iconCssClass": "contributions",
		},

		"company-information": {
			"heading": "Company Information",
			"mainText": "We need some information about your company in order to help process your health insurance information with your carriers.",
			"footer": '<i><strong>Important:</strong> Complete immediately to avoid delays.</i>',
			"iconCssClass": "company-information",
		},

	},
});


App.BoRWaitingPeriodSelector = Ember.View.extend({
	templateName: 'bor-waiting-periods-selector',

	anyOptions: Ember.computed.gt('content.length', 1),

	content: Ember.computed(function() {
		var waitingPeriods = this.get('waitingPeriods');
		// Id should be empty so that hasMissingValues can recognize it.
		var waitingPeriodNotListed = Ember.Object.create({id:"NOT_LISTED", name: "I don't see my waiting period listed"});
		var waitingPeriodUnknown = Ember.Object.create({id:"UNKNOWN", name: "I want Zenefits to confirm my waiting period"});

		var uiPeriods = App.UiSupportedPeriods;
		var value = this.get('value');
		// Filter out any non-selectable periods, except carrier defaults
		waitingPeriods = waitingPeriods.filter(function(period) {
			return uiPeriods.contains(period.get('waitingPeriod')) || period.get('isDefault') || period.get('waitingPeriod') == value;
		});

		var mappedWaitingPeriods = waitingPeriods.map(function(period) {
			var waitingPeriodId = period.get('waitingPeriod');
			return Ember.Object.create({
				id: waitingPeriodId,
				name: App.WAITING_PERIODS_MAP[waitingPeriodId],
				isDefault: period.get('isDefault'),
			});
		});
		mappedWaitingPeriods.unshift(waitingPeriodUnknown);
		mappedWaitingPeriods.push(waitingPeriodNotListed);
		return mappedWaitingPeriods;
	}).property('waitingPeriods.@each.isLoaded'),
});

App.WaitingPeriodSelector = Ember.View.extend({
	templateName: 'enrollment/waiting-periods-selector',
	waitingPeriods: null,

	defaultSetter: (function() {
		if (this.get('value')) {
			return;
		}

		var defaultPeriod = this.get('content').find(function(obj) {
			return obj.get('isDefault');
		});

		this.set('selection', defaultPeriod);
	}).observes('content.@each.isDefault'),

	valueDisplay: Ember.computed(function() {
		var value = this.get('value');
		var content = this.get('content'); // hacks to force computed to load

		return App.WAITING_PERIODS_MAP[value];
	}).property('value'),

	anyOptions: Ember.computed.gte('content.length', 1),

	content: Ember.computed(function() {
		var value = this.get('value');
		var waitingPeriods = this.get('waitingPeriods');

		if (!waitingPeriods.get('isLoaded') || !waitingPeriods.everyProperty('isLoaded')) {
			return [];
		}

		var uiPeriods = App.UiSupportedPeriods;
		// Filter out any non-selectable periods, except carrier defaults
		waitingPeriods = waitingPeriods.filter(function(period) {
			return uiPeriods.contains(period.get('waitingPeriod')) || period.get('isDefault') || period.get('waitingPeriod') == value;
		});

		// If there are no periods
		if (waitingPeriods.get('length') == 0) {
			waitingPeriods = uiPeriods.map(function(period) {
				return Ember.Object.create({
					waitingPeriod: period,
				});
			});

			waitingPeriods[0].set('isDefault', true);
		}

		return waitingPeriods.map(function(period) {
			var waitingPeriodId = period.get('waitingPeriod');

			return Ember.Object.create({
				id: waitingPeriodId,
				name: App.WAITING_PERIODS_MAP[waitingPeriodId],
				isDefault: period.get('isDefault'),
			});
		});
	}).property('waitingPeriods.@each.isLoaded', 'waitingPeriods.isLoaded'),
});

App.AgeRangeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:"", name:"Select"}),
		Ember.Object.create({id:"0 to 29", name:"0 to 29"}),
		Ember.Object.create({id:"30 to 39", name:"30 to 39"}),
		Ember.Object.create({id:"40 to 49", name:"40 to 49"}),
		Ember.Object.create({id:"50 to 54", name:"50 to 54"}),
		Ember.Object.create({id:"55 to 59", name:"55 to 59"}),
		Ember.Object.create({id:"60 to 64", name:"60 to 64"}),
		Ember.Object.create({id:"65+", name:"65+"})
	],
});

App.CobraField = App.WholeNumberField.extend({
	enableDetails: function() {
		var value = this.get('value');
		if (value && value > 0) {
			$('#cobraDetails').show();
		}
		else {
			$('#cobraDetails').hide();
		}
	}.observes('value')
});

App.CoverageHMOPPOType = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:null, name:""}),
		Ember.Object.create({id:"HMO", name:"HMO"}),
		Ember.Object.create({id:"PPO", name:"PPO"}),
		Ember.Object.create({id:"EPO", name:"EPO"}),
		Ember.Object.create({id:"POS", name:"POS"}),
		Ember.Object.create({id:"HD", name:"HD"})
	],
});

App.CobraEventSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:null, name:""}),
		Ember.Object.create({id:"Termination", name:"Termination"}),
		Ember.Object.create({id:"Hours reduced", name:"Hours reduced"}),
		Ember.Object.create({id:"Divorce from employee", name:"Divorce from employee"}),
		Ember.Object.create({id:"Death of employee", name:"Death of employee"})
	],
});

App.CobraTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:"COBRA", name:"COBRA"}),
		Ember.Object.create({id:"Cal-COBRA", name:"Cal-COBRA"})
	],
});


App.CarrierSelectView = App.SelectBox.extend({
	sorting: false,

	optionLabelPath: "content.displayName",
	optionValuePath: "content.displayName",
	didChange: function() {
		var select = this.$();
		if (select) {
			var selectbox = select.parent();
			var selection = selectbox.find('option:selected').text();
			selectbox.children('span.selected-value').text(selection);
		}
	}.observes('selection')
});

App.BusinessCarrierSelectView = App.CarrierSelectView.extend({
	contentObserver: function(){
		var select = this.$();
		if (select) {
			var selectbox = select.parent();
			selectbox.children('span.selected-value').text(this.get('prompt'));
		}

	}.observes('content'),
});

function getCarrierNames() {
	var carriers = [];
	var result = '';

	for (var i = 0; i < arguments.length; i++) {
		var companyHealthCarrier = arguments[i];
		if(companyHealthCarrier && carriers.indexOf(companyHealthCarrier.get('carrier.displayName')) == -1) {
			carriers.push(companyHealthCarrier.get('carrier.displayName'));
		}
	}

	if(carriers.length) {
		result += carriers[0];
	}
	for(var i = 1; i < carriers.length - 1; i++) {
		result += ', ' + carriers[i];
	}
	if(i < carriers.length) {
		result += " and " + carriers[i];
	}
	return result;
}

App.DomainField = App.TextField.extend({
	valueDidChange: function() {
		var controller = this.get('targetObject');
		if (controller) {
			controller._updateEmails();
		}
	}.observes('value')
});

App.EmailFormatSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id: 'first', name: 'rocky'}),
		Ember.Object.create({id: 'flast', name: 'rbalboa'}),
		Ember.Object.create({id: 'first.last', name: 'rocky.balboa'}),
		Ember.Object.create({id: 'firstlast', name: 'rockybalboa'})
	],
	controller: null,
	optionLabelPath: "content.name",
	optionValuePath: "content.id",
	change: function() {
		var controller = this.get('controller');
		if (controller) {
			controller._updateEmails();
		}
	}
});

App.PlanRequirementsRow = Ember.Object.extend({
	carrier: null,
	participationRule: null,
	forceParticipation: false,

	minParticipation: Ember.computed(function() {
		return Number(this.get('participationRule.firstObject.percentage'));
	}).property('participationRule.firstObject.percentage'),

	capsLineOfCoverage: Ember.computed(function(){
		var loc = this.get('enrollment.lineOfCoverage') || "";

		return Ember.String.capitalize(loc);
	}).property('enrollment.lineOfCoverage'),

	minEmployees: Ember.computed.any('participationRule.firstObject.minEmployeesCount'),
});

App.BenefitspreviewController = Ember.Controller.extend({
	benefitsPreview: Ember.computed.alias('model'),
	isActivated: Ember.computed(function() {
		return (this.get('benefitsPreview') && !this.get('benefitsPreview.softDeleted'));
	}),
	isReady: Ember.computed.not('benefitsPreview.isSaving'),
	currentPromise: null,

	updateState: function() {
		var shouldBeActive = this.get('isActivated');
		var hasBenefitsPreview = (this.get('benefitsPreview') && !this.get('benefitsPreview.softDeleted'));
		var promise = null;

		if (shouldBeActive && !hasBenefitsPreview) {
			promise = this.activate();
		} else if (!shouldBeActive && hasBenefitsPreview) {
			promise = this.deactivate();
		} else {
			return;
		}

		var controller = this;
		this.set('currentPromise', promise.then(function() {
			controller.set('currentPromise', null);
			controller.updateState.apply(controller);
		}));
	},

	activate: function() {
		var benefitsPreview = this.get('benefitsPreview');

		if (benefitsPreview) {
			benefitsPreview.set('softDeleted', false);
		} else {
			benefitsPreview = App.BenefitsPreview.createRecord({
				company: this.get('company'),
			});
		}

		this.set('benefitsPreview', benefitsPreview);
		return benefitsPreview.save();
	},

	deactivate: function() {
		var benefitsPreview = this.get('benefitsPreview');
		this.set('benefitsPreview.softDeleted', true);

		return benefitsPreview.save();
	},

	actions: {
		toggleBenefitsPreview: function() {
			if (!this.get('currentPromise')) {
				this.updateState();
			}
		},
	},
});

App.DateObj = Ember.Object.extend({
	baseDate: null,

	dayBefore: Ember.computed(function() {
		var dayBefore = moment(this.get('baseDate')).subtract(1);

		return App.DateObj.create({
			baseDate: dayBefore,
		});
	}).property('baseDate'),

	shortPretty: Ember.computed(function() {
		return this.get('baseDate').format('MMM D');
	}).property('baseDate'),

	longPretty: Ember.computed(function() {
		return this.get('baseDate').format('MMM D, YYYY');
	}).property('baseDate'),
});

Ember.computed.dateObj = function(attr, dateFormat) {
	return Ember.computed(function() {
		return App.DateObj.create({
			baseDate: this.get(attr),
		});
	}).property(attr);
};

App.ApproversController = Ember.ObjectController.extend(zen._HiringAndEmployeesAppNavBarMixin, {
});
